import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Distributor } from '../Distributor';
import { DistributorService } from '../distributor.service';

@Component({
  selector: 'app-distributor-list',
  templateUrl: './distributor-list.component.html'
})
export class DistributorListComponent implements OnInit {

  @ViewChild('referenceFilter', { static: false }) referenceFilter: ElementRef;
  @ViewChild('labelFilter', { static: false }) labelFilter: ElementRef;
  @ViewChild('isActiveFilter', { static: false }) isActiveFilter: ElementRef;

  distributors: Distributor[] = [];
  filteredDistributors: Distributor[] = [];

  loading: boolean = true;
  error: string = null;

  distributorsPerPageOptions: number[] = [5, 10, 20, 50, 100];
  distributorsPerPage: number = 5;

  constructor(
    private distributorService: DistributorService
  ) { }


  ngOnInit() {
    this.getDistributors();
  }
  getDistributors() {
    this.distributorService.getDistributors().subscribe(datas => {
      this.distributors = datas.details;
      this.filteredDistributors = datas.details;
      this.loading = false;
    }, error => {
      this.error = error.statusText;
      this.loading = false;
    })
  }
  updateFilter() {
    this.filteredDistributors = this.distributors
      .filter(distributor => {
        return this.compareValues(distributor.reference, this.referenceFilter);
      })
      .filter(distributor => {
        return this.compareValues(distributor.name, this.labelFilter);
      })
      .filter(distributor => {
        return this.compareValues(distributor.isActive + '', this.isActiveFilter);
      })
  }
  compareValues(parameter: string = '', criteria: ElementRef) {
    return criteria === undefined ? true : criteria.nativeElement.value === '' || parameter.match(new RegExp(criteria.nativeElement.value, 'i'))
  }
  clearFilter() {
    this.referenceFilter.nativeElement.value = '';
    this.labelFilter.nativeElement.value = '';
    this.isActiveFilter.nativeElement.value = '';
    this.updateFilter();
  }
  changeDistributorsPerPage(value) {
    this.distributorsPerPage = value;
  }
}
