import { Country } from '../country/Country';
import { Distributor } from '../distributor/Distributor';

export class DistributionSite {
    public id: number;

    public reference: string;

    public name: string;

    public distributor: Distributor;

    public country: Country;

    public urlCancel: string;

    public urlReturn: string;

    public urlAutoReturn: string;

    public isActive: boolean;

    public urlAutoReturnBasicAuth: string;

    public constructor() {
        this.urlCancel = '';
        this.urlReturn = '';
        this.urlAutoReturn = '';
        this.distributor = new Distributor();
        this.country = new Country();
        this.isActive = true;
        this.urlAutoReturnBasicAuth = '';
    }
}
