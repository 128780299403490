import { Component, OnInit, ViewChild, ElementRef, asNativeElements } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { CurrencyRateService } from '../currency-rate.service';

@Component({
  selector: 'app-currency-rate-list',
  templateUrl: './currency-rate-list.component.html',
  styleUrls: ['./currency-rate-list.component.css']
})

export class CurrencyRateList implements OnInit {
    @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
    @ViewChild('currencyFilter', {static: false}) currencyFilter: ElementRef;
    @ViewChild('rateFilter', {static: false}) rateFilter: ElementRef;

    loading: boolean = true;
    error: string = null;

    datas: Array<any> = [];
    temp: Array<any> = [];
    filters: Array<any> = [];

    // Gestion nombre de lignes par page
    currencyRatePageOptions = [5, 10, 20, 50, 100];
    currencyRatePerPage = 5;

    constructor(private currencyRateService: CurrencyRateService) { }

    ngOnInit(): void {
        this.getCurrencyRate();
    }

    getCurrencyRate() {
      this.currencyRateService.getCurrencyRate().subscribe(data => {
        this.loading = false;
        this.error = null;
        this.datas = data.details;
        this.temp = this.datas;
      }, error => {
        this.error = error.statusText;
        this.loading = false;
      })
    }

    saveCurrencyRate() {
      this.loading = true;
      this.currencyRateService.postCurrencyRate(this.datas).subscribe(data => {
        this.getCurrencyRate();
      }, error => {
        this.error = error.statusText;
        this.loading = false;
      });
    }

    changeCurrencyRatePerPage(value) {
      this.currencyRatePerPage = value;
    }

    updateFilter(event, prop) {
        // Vider tous les champs de recherche sans perdre le champs courant
        const tempQuery = event.target.value.replace(/,/g, '.');
        this.resetFilter();
        event.target.value = tempQuery;
    
        const query = event.target.value.toLowerCase();
    
        // filtre les données
        const temp = this.temp.filter(rows => {
          if (rows[prop]) {
            return rows[prop].toString().toLowerCase().indexOf(query) !== -1 || !query;
          } else {
            return !query;
          }
        });
    
        this.datas = temp;
        this.table.offset = 0;
    }

    resetFilter() {
        this.currencyFilter.nativeElement.value = '';
        this.rateFilter.nativeElement.value = '';
    }

    clearFilters() {
        this.resetFilter();
        this.datas = this.temp;
        this.table.offset = 0;
    }
}
