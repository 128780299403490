import { Component, OnInit } from '@angular/core';
import { Distributor } from '../Distributor';
import { faArrowAltCircleLeft, faTimesCircle, faCheckCircle, faEdit } from '@fortawesome/free-solid-svg-icons';
import { DistributorService } from '../distributor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-distributor-detail',
  templateUrl: './distributor-detail.component.html'
})
export class DistributorDetailComponent implements OnInit {

  distributor: Distributor = new Distributor();
  id: number;

  loading: boolean = true;
  error: string = null;

  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;
  faEdit = faEdit;

  constructor(
    private distributorService: DistributorService,
    private activeRoute: ActivatedRoute,
    private router: Router
  ) {

  }

  ngOnInit() {
    this.id = this.activeRoute.snapshot.params.id;
    this.getDistributorById(this.id);
  }

  getDistributorById(id) {
    this.distributorService.getDistributorById(id).subscribe(data => {
      data.details ? this.distributor = data.details : this.router.navigate(['/distributors']);
      this.setErrorAndLoading(null, false);
    }, error => {
      this.setErrorAndLoading(error.statusText, false);
    })
  }

  setErrorAndLoading(error: string, loading: boolean) {
    this.error = error;
    this.loading = loading;
  }

  changeIsActive(){
    this.setErrorAndLoading(null, true);
    let newDistributor = {...this.distributor, isActive: !this.distributor.isActive};
    this.distributorService.putDistributor(newDistributor).subscribe(result => {
      this.distributor = result.details;
      this.setErrorAndLoading(null, false);
    }, error => {
      this.setErrorAndLoading(error.statusText, false);

    });
  }
}
