import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EditorService {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, private _router: Router) { }

  ngOnInit() { }

  getEditors(params = new HttpParams()): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'editors?' + params.toString(), { headers: this.httpHeaders });
  }

  getEditorById(id): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'editor/' + id, { headers: this.httpHeaders });
  }

  putEditor(param): Observable<any> {
    return this.http.put<any>(environment.ApiUrl + 'editor/' + param.id, param, { headers: this.httpHeaders });
  }
  postEditor(param): Observable<any> {
    return this.http.post<any>(environment.ApiUrl + 'editor', param, { headers: this.httpHeaders });
  }

  getNotifications(): Observable<any> {
    return this.http.get(environment.ApiUrl + 'notifications?for=Editor', { headers: this.httpHeaders });
  }

  getLangs(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'langs', { headers: this.httpHeaders });
  }

  getEditorNotificationsRecipient(id): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'editor/' +  id + '/notification/recipients', { headers: this.httpHeaders });
  }
}
