import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FileSaverService } from 'ngx-filesaver';
import { DatePipe } from '@angular/common'
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { SubscriptionService } from '../subscription.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ["./subscription-list.component.css"]
})
export class SubscriptionListComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('countryFilter', { static: false }) countryFilter: ElementRef;
  @ViewChild('currencyFilter', { static: false }) currencyFilter: ElementRef;
  @ViewChild('distributionSiteFilter', { static: false }) distributionSiteFilter: ElementRef;
  @ViewChild('editorFilter', { static: false }) editorFilter: ElementRef;
  @ViewChild('productFilter', { static: false }) productFilter: ElementRef;
  @ViewChild('periodFilter', { static: false }) periodFilter: ElementRef;
  @ViewChild('productTypeFilter', { static: false }) productTypeFilter: ElementRef;
  @ViewChild('distributorCustomerIdFilter', { static: false }) distributorCustomerIdFilter: ElementRef;
  @ViewChild('tansactionReferenceFilter', { static: false }) tansactionReferenceFilter: ElementRef;
  @ViewChild('dateFilter', { static: false }) dateFilter: ElementRef;
  @ViewChild('dateFilterActivationDate', { static: false }) dateFilterActivationDate: ElementRef;
  @ViewChild('nbElaspedDaysFilter', { static: false }) nbElaspedDaysFilter: ElementRef;
  @ViewChild('nbOfRenewalsFilter', { static: false }) nbOfRenewalsFilter: ElementRef;

  loading: boolean = true;
  datas = [];
  temp = [];
  activeFilter = [];
  objectKeys = Object.keys;

  subscriptionStatus = [];
  countries = [];
  distributionSite = [];
  editor = [];
  product = [];
  productType = [];
  period = [];

  filters = [];
  sortBy = 'createdAt';
  sortOrder = 'desc';

  // Gestion nombre de lignes par page
  subscriptionsPerPageOptions = [5, 10, 20, 50, 100];
  subscriptionsPerPage = 5;
  constructor(private subscriptionService: SubscriptionService, public datepipe: DatePipe, private router: Router, private translate: TranslateService, private fileSaverService: FileSaverService) { }

  ngOnInit() {
      this.getSubscriptions();
  }

  getSubscriptions() {
    this.subscriptionService.getSubscriptions().subscribe(async data => {
        this.temp = [...data.details];
        this.datas = data.details;
        this.loading = false;
        await this.changeSubscriptionTypeLabel();
        this.fillSelects();
      },
      error => {
        console.log(error);
        this.loading = false;
      },
      () => { }
    );
  }

  async changeSubscriptionTypeLabel() {
    let renewableLabel = null;
    await new Promise((resolve) => {
      this.translate.get('SUBSCRIPTION.TYPE.Renewable').subscribe((result) => {
        renewableLabel = result;
        this.datas.forEach((elem : any) => {
          elem.productTypeLabel = elem.productTypeLabel === "recurringSubscription" ? renewableLabel : "One Shot";
        })
        resolve(renewableLabel);
      })
    });
  }

  fillSelects() {
    this.subscriptionStatus = this.datas
    .map(item => item.subscriptionStatus)
    .filter((value, index, self) => self.indexOf(value) === index);

    this.countries = this.datas
    .map(item => item.country)
    .filter((value, index, self) => self.indexOf(value) === index);

    this.distributionSite = this.datas
    .map(item => item.distributionSiteName)
    .filter((value, index, self) => self.indexOf(value) === index);

    this.editor = this.datas
    .map(item => item.editorName)
    .filter((value, index, self) => self.indexOf(value) === index);

    this.product = this.datas
    .map(item => item.productLabel)
    .filter((value, index, self) => self.indexOf(value) === index);

    this.productType = this.datas
    .map(item => item.productTypeLabel)
    .filter((value, index, self) => self.indexOf(value) === index);

    this.period = this.datas
    .map(item => item.subscriptionPeriod)
    .filter((value, index, self) => self.indexOf(value) === index);
  }

  exportSubscriptions() {
    var params = '';
    this.activeFilter.forEach((element, index) => {
      if (index === 0)
        params += '?';
      else
        params += '&';
      if (element.dateInput === true) {
        var beginDate = element.value[0];
        var endDate = element.value[1];
        params += element.prop + 'BeginDate=' + this.datepipe.transform(beginDate, 'yyyy-MM-dd') + '&' + element.prop + 'EndDate=' + this.datepipe.transform(endDate, 'yyyy-MM-dd');
      } else {
        params += element.prop + '=' + element.value;
      }
    });

    this.subscriptionService.exportSubscrptions(params).subscribe(
      data => {
        var filename = "Export Subscriptions OLink agg " + this.datepipe.transform(new Date(), 'ddMMyyyy');
        this.fileSaverService.save(data, filename + '.csv');
      },
      error => { console.log(error); },
      () => { }
    );
  }

  updateFilter(inputValue, prop, dateInput = false) {
    // Add filter
    if (this.activeFilter.find((object) => object.prop === prop) === undefined) {
      this.activeFilter.push({prop: prop, value: dateInput !== true ? inputValue.target.value : inputValue, dateInput: dateInput});
    } else {
        // Delete filter
      if (dateInput === false && inputValue.target.value === '') {
        let index = this.activeFilter.findIndex((object) => object.prop === prop);
        this.activeFilter.splice(index, 1);
      } else {
        // Modif filter
        let index = this.activeFilter.findIndex((object) => object.prop === prop);
        this.activeFilter[index].value = dateInput !== true ? inputValue.target.value : inputValue;
        }
    }

    // On reprend les données intiales
    let tmp = this.temp;

    // On filtre les données
    this.activeFilter.forEach(filter => {

      // Check si filtre de type date
      if (filter.dateInput === true) {
        tmp = tmp.filter(rows => {
          if (!filter.value) {
            return !filter.value;
          } else {
            const dateDebut = filter.value[0].setHours(0, 0, 0);
            const dateFin = filter.value[1].setHours(23, 59, 59);
            const tempDate = new Date(rows[filter.prop]);
            return tempDate > dateDebut && tempDate < dateFin || !filter.value;
          }
        });
      } else {
        tmp = tmp.filter(rows => {
          if (rows[filter.prop]) {
            return rows[filter.prop].toString().toLowerCase().indexOf(filter.value.toLowerCase()) !== -1 || !filter.value.toLowerCase();
          } else {
            return !filter.value.toLowerCase();
          }
        });
      }
    });

    this.datas = tmp;

    this.table.offset = 0;
  }

  consultSubscription(row) {
    this.router.navigate(['/subscription/' +  row.subscriptionId]);
  }

  changeSubscriptionPerPage(value) {
    this.subscriptionsPerPage = value;
  }

  resetFilter() {
    this.countryFilter.nativeElement.value = undefined;
    this.currencyFilter.nativeElement.value = undefined;
    this.dateFilter.nativeElement.value = '';
    this.distributionSiteFilter.nativeElement.value = undefined;
    this.periodFilter.nativeElement.value = undefined;
    this.editorFilter.nativeElement.value = undefined;
    this.productFilter.nativeElement.value = undefined;
    this.productTypeFilter.nativeElement.value = undefined;
    this.distributorCustomerIdFilter.nativeElement.value = '';
    this.tansactionReferenceFilter.nativeElement.value = '';
    this.nbElaspedDaysFilter.nativeElement.value = '';
    this.nbOfRenewalsFilter.nativeElement.value = '';
    this.dateFilterActivationDate.nativeElement.value = '';
  }

  clearFilters() {
    this.resetFilter();
    this.activeFilter = [];
    this.datas = this.temp;
    this.table.offset = 0;
  }
}
