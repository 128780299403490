import { Component, OnInit } from '@angular/core';
import { faArrowAltCircleLeft, faTimes, faCheck, faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute, Router } from '@angular/router';
import { EditorService } from '../editor.service';
import { Editor } from '../Editor';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';

@Component({
  selector: 'app-editor-new-edit',
  templateUrl: './editor-new-edit.component.html',
  styleUrls: ['./editor-new-edit.component.css']
})
export class EditorNewEditComponent implements OnInit {

  loading: boolean = true;
  error: string = null;
  id: number;

  form: FormGroup;
  
  //icons
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faPlusCircle = faPlusCircle;
  faTimesCircle = faTimesCircle;
  faTimes = faTimes;
  faCheck = faCheck;

  langs: Array<any>;
  constructor(
    private route: ActivatedRoute,
    private editorService: EditorService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    this.form = formBuilder.group({
      reference: new FormControl('', [
        Validators.required,
        Validators.maxLength(5),
        Validators.pattern('^[0-9a-zA-Z_-]{1,5}$')
      ]),
      label: new FormControl('', [
        Validators.required,
        Validators.maxLength(255)
      ]),
      isActive: new FormControl('', [
        Validators.required
      ]),
      notifications: this.formBuilder.array([]),
    })
  }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.getEditor();
    this.editorService.getLangs().subscribe(data => {
      this.langs = data.details;
    })
  }

  getEditor() {
    if (this.id) {
      this.editorService.getEditorById(this.id).subscribe(dataEditor => {
        this.error = null;

        this.editorService.getNotifications().subscribe(
          data => {
            let notifFormArray = this.form.get('notifications') as FormArray;
            // On ajoute chaque FormGroup Notification au FormArray prévu
            data.details.forEach(
              notif => {
                let newNotifGroup = this.formBuilder.group({
                  code: [notif.code],
                  isActivated: [false],
                  recipients: this.formBuilder.array([])
                });
                notifFormArray.push(newNotifGroup);
              }
            )
            this.loading = false;
            dataEditor.details ? this.setFormValues(dataEditor.details.reference, dataEditor.details.name, dataEditor.details.isActive, dataEditor.details.sendingNotification) : this.router.navigate(['/editors']);
          });
      }, error => {
        this.error = error.statusText;
        this.loading = false;
      })
    } else {
      this.editorService.getNotifications().subscribe(
        data => {
          let notifFormArray = this.form.get('notifications') as FormArray;
          // On ajoute chaque FormGroup Notification au FormArray prévu
          data.details.forEach(
            notif => {
              let newNotifGroup = this.formBuilder.group({
                code: [notif.code],
                isActivated: [false],
                recipients: this.formBuilder.array([])
              });
              notifFormArray.push(newNotifGroup);
            }
          )
          this.setFormValues('', '', true, false);
        });
      
      this.loading = false;
    }
  }

  manageNotificationRecipient(notifIdx, toAdd, rcpIdx = null) {
    let notifArray = this.form.get('notifications') as FormArray;
    let notif = notifArray.controls[notifIdx];
    let notifGroup = notif as FormGroup;
    let rcpArray = notif.get('recipients') as FormArray;
    if (toAdd) {
      rcpArray.push(this.formBuilder.group({
        email: [''],
        lang: [''],
      }))
    }
    else if (!toAdd && rcpIdx != null) {
      rcpArray.removeAt(rcpIdx);
    }
  }

  setFormValues(reference, label, isActive, sendingNotification) {
    this.form.patchValue({ reference, label, isActive});

    if (sendingNotification) {
      let notifArray = this.form.get('notifications') as FormArray;
      notifArray.controls.forEach(
        notif => {
          let notifGroup = notif as FormGroup;
          let rcpArray = notif.get('recipients') as FormArray;
          if (notif.value.code == "ORDERS_DAILY_REPORT_EDITOR") {
            notifGroup.get('isActivated').setValue(true);
            this.editorService.getEditorNotificationsRecipient(this.id).subscribe((data) => {
              data.details.forEach(
                recipient => {
                  rcpArray.push(this.formBuilder.group({
                    email: [recipient.email],
                    lang: [recipient.lang.local],
                  }))
                }
              )
            })
          }
        }
      )
    }
  }

  submitAction() {
    this.form.controls.reference.markAsDirty();
    this.form.controls.label.markAsDirty();
    this.form.controls.isActive.markAsDirty();
    if (this.form.valid) {
      let newEditor = {
        'reference': this.form.value.reference,
        'name': this.form.value.label,
        'isActive': this.form.value.isActive === 'true' ? true : false,
        'notifications': this.form.value.notifications
      };
      this.loading = true;
      this.id ? this.updateEditor(newEditor) : this.createEditor(newEditor);
    } else {
      this.error = 'TEMPLATE.FORM.Invalid';
    }
  }
  createEditor(newEditor) {
    this.editorService.postEditor(newEditor).subscribe(result => {
      this.router.navigate(['/editor', result.details.id]);
    }, error => {
      this.loading = false;
      this.error = error.statusText;
    })
  }
  updateEditor(newEditor) {
    newEditor = { ...newEditor, 'id': this.id };
    this.editorService.putEditor(newEditor).subscribe(result => {
      this.router.navigate(['/editor', this.id])
    }, error => {
      this.loading = false;
      this.error = error.statusText;
    })
  }
}
