import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ApiRouteService } from '../api-route.service';

@Component({
  selector: 'app-api-user-list',
  templateUrl: './api-route-list.component.html',
  styleUrls: ["./api-route-list.component.css"]
})
export class ApiRouteListComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('labelFilter', { static: false }) labelFilter: ElementRef;
  @ViewChild('routeFilter', { static: false }) routeFilter: ElementRef;
  @ViewChild('activeFilter', { static: false }) activeFilter: ElementRef;
  @ViewChild('roleFilter', { static: false }) roleFilter: ElementRef;

  loading: boolean = true;
  datas = [];
  temp = [];
  objectKeys = Object.keys;

  filters = [];
  sortBy = 'createdAt';
  sortOrder = 'desc';

  // Tableaux des options possibles des selects pour la recherche
  actives = [];

  //   // Gestion nombre de lignes par page
  apiRoutesPerPageOptions = [5, 10, 20, 50, 100];
  apiRoutesPerPage = 5;

  constructor(private apiRouteService: ApiRouteService) { }

  ngOnInit() {
    this.getApiRoutes();
  }

  getApiRoutes() {
    this.apiRouteService.getApiRoutes().subscribe(
      data => {
        this.temp = [...data.details];
        this.datas = data.details;
        this.loading = false;
        this.fillSelects();
      },
      error => {
        console.log(error);
        this.loading = false;
      },
      () => { }
    );
  }

  fillSelects() {
    this.actives = this.datas
      .map(item => item.isActive)
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  changeApiRoutesPerPage(value) {
    this.apiRoutesPerPage = value;
  }

  updateFilter(event, prop) {
    // Vider tous les champs de recherche sans perdre le champs courant
    const tempQuery = event.target.value;
    this.resetFilter();
    event.target.value = tempQuery;

    const query = event.target.value.toLowerCase();

    // filtre les données
    const temp = this.temp.filter(rows => {
      if (rows[prop]) {
        return rows[prop].toString().toLowerCase().indexOf(query) !== -1 || !query;
      } else {
        return !query;
      }
    });

    this.datas = temp;
    this.table.offset = 0;
  }

  resetFilter() {
    this.labelFilter.nativeElement.value = '';
    this.routeFilter.nativeElement.value = '';
    this.activeFilter.nativeElement.value = '';
    this.roleFilter.nativeElement.value = '';
  }

  clearFilters() {
    this.resetFilter();
    this.datas = this.temp;
    this.table.offset = 0;
  }
}
