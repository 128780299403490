import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ActivatedRoute, Router } from '@angular/router';
import { VoucherService } from '../voucher.service';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { faArrowAltCircleLeft, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { $ } from 'protractor';

@Component({
    selector: 'app-voucher-edit',
    templateUrl: './voucher-edit.component.html'
})
export class VoucherEditComponent implements OnInit {

    @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

    //icons
    faArrowAltCircleLeft = faArrowAltCircleLeft;
    faTimes = faTimes;
    faCheck = faCheck;

    id = undefined;
    voucher = undefined;
    wait = true;
    status = [];
    params = undefined;


    form: FormGroup;

    constructor(private voucherService: VoucherService, private route: ActivatedRoute, formBuilder: FormBuilder, private _router: Router) {
        this.form = formBuilder.group({
            status: new FormControl('')
        });
    }

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('id');
        this.getVoucher();
        this.getVoucherStatus();
    }

    getVoucher() {
        this.voucherService.getVoucher(this.id).subscribe(
            data => {
                this.voucher = data.details;
                this.form.addControl('status', new FormControl(this.voucher.status));
                this.wait = false;
            },
            error => { console.log(error); },
            () => { }
        )
    }

    getVoucherStatus() {
        this.voucherService.getAllowedVoucherStatus().subscribe(
            data => {
                this.status = data.details;
                this.wait = false;
            },
            error => { console.log(error); },
            () => { }
        )
    }

    submitForm() {
        var values = this.form.value;
        this.params = { 'status': values.status };
        this.voucherService.putVoucher(this.id, this.params).subscribe(
            data => {
                this._router.navigate(['/voucher/' + this.id])
            },
            error => {
                console.log(error);
            },
            () => { }
        );
    }
}
