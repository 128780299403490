import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiRouteService } from '../api-route.service';
import {
    faEdit, faUserTimes, faUserCheck,
    faArrowAltCircleLeft, faCheckCircle,
    faTimesCircle, faTimes, faCheck,
    faEnvelope, faCalendar
} from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-api-route-detail',
    templateUrl: './api-route-detail.component.html',
    styleUrls: ['./api-route-detail.component.css']
})
export class ApiRouteDetailComponent implements OnInit {

    @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

    //icons
    faEdit = faEdit;
    faUserTimes = faUserTimes;
    faUserCheck = faUserCheck;
    faArrowAltCircleLeft = faArrowAltCircleLeft;
    faCheckCircle = faCheckCircle;
    faTimesCircle = faTimesCircle;
    faTimes = faTimes;
    faCheck = faCheck;
    faEnvelope = faEnvelope;
    faCalendar = faCalendar;

    id = undefined;
    service = undefined;
    calls = undefined;
    call_stats = undefined;
    wait = true;
    total = 0;
    temp = undefined;

    maxDate = new Date();
    minDate = new Date();
    maxDateDisplayed = new Date();
    minDateDisplayed = new Date();
    sameDay = false;

    //Gestion des routes
    apiRouteCallPerPage = 10;

    constructor(private apiRouteService: ApiRouteService,
        private _router: Router, private _route: ActivatedRoute) { }

    ngOnInit() {
        this.minDate.setDate(this.maxDate.getDate() - 14)
        this.minDateDisplayed = this.minDate;
        this.id = this._route.snapshot.paramMap.get('id');
        this.getApiRoute();
        this.getApiRouteCalls();
    }

    getApiRoute() {
        this.apiRouteService.getApiRoute(this.id).subscribe(
            data => {
                this.service = data.details.route;
                this.call_stats = data.details.calls;
                var localError = parseInt(this.call_stats.ERROR) || 0;
                var localSuccess = parseInt(this.call_stats.SUCCESS) || 0;
                this.total = localError + localSuccess;
                this.wait = false;
            },
            error => { console.log(error); },
            () => { }
        );
    }

    getApiRouteCalls() {
        this.apiRouteService.getApiRouteApiCalls(this.id).subscribe(
            data => {
                this.temp = [...data.details];
                this.calls = data.details;
            },
            error => { console.log(error); },
            () => { }
        );
    }

    parseJSON(details) {
        return JSON.parse(details);
    }

    updateDateFilter(value, prop) {
        const temp = this.temp.filter(rows => {
            if (!value) {
                return !value;
            } else {
                if (this.minDateDisplayed.getDay() == this.maxDateDisplayed.getDay()) {
                    this.sameDay = true;
                } else {
                    this.sameDay = false;
                }
                this.minDateDisplayed = value[0];
                this.maxDateDisplayed = value[1];
                const dateDebut = value[0].setHours(0, 0, 0);
                const dateFin = value[1].setHours(23, 59, 59);
                const tempDate = new Date(rows[prop]);
                return tempDate > dateDebut && tempDate < dateFin || !value;
            }
        });

        this.calls = temp;
        this.table.offset = 0;
    }
}
