import { Component, OnInit, Input } from '@angular/core';
import { faCircleNotch, faRedo, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})
export class LoadingComponent implements OnInit {
  @Input() size = '20px';
  
  //icon
  faRedo = faRedo;
  faSpinner = faSpinner;
  faCircleNotch = faCircleNotch;


  constructor() { }

  ngOnInit() {
  }

}
