import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { ApiRouteDetailComponent } from './api-administration/api-route/detail/api-route-detail.component';
import { ApiRouteListComponent } from './api-administration/api-route/list/api-route-list.component';
import { ApiUserDetailComponent } from './api-administration/api-user/detail/api-user-detail.component';
import { ApiUserEditComponent } from './api-administration/api-user/edit/api-user-edit.component';
import { ApiUserListComponent } from './api-administration/api-user/list/api-user-list.component';
import { ApiUserNewComponent } from './api-administration/api-user/new/api-user-new.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { DistributableProductNewComponent } from './product/new-distributable-product/distributable-product-new.component';
import { DistributableProductDetailComponent } from './product/detail-distributable-product/distributable-product-detail.component';
import { HomeComponent } from './home/home.component';
import { ProductDetailComponent } from './product/detail/product-detail.component';
import { ProductListComponent } from './product/list/product-list.component';
import { ProductNewComponent } from './product/new-product/product-new.component';
import { PriceListComponent } from './price/list/price-list.component';
import { TransactionComponent } from './transaction/list/transaction-list.component';
import { TransactionEditComponent } from './transaction/edit/transaction-edit.component';
import { TransactionDetailComponent } from './transaction/detail/transaction-detail.component';
import { VoucherComponent } from './voucher/list/voucher-list.component';
import { VoucherEditComponent } from './voucher/edit/voucher-edit.component';
import { VoucherDetailComponent } from './voucher/detail/voucher-detail.component';

import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { JwtModule } from '@auth0/angular-jwt';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ChartsModule, ThemeService } from 'ng2-charts';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FileSaverModule } from 'ngx-filesaver';
import { DatePipe } from '@angular/common'

import { JwtInterceptor } from './authentication/jwt.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { DistributionSiteNewEditComponent } from './distribution-site/new-edit/distribution-site-new-edit.component';
import { DistributionSiteListComponent } from './distribution-site/list/distribution-site-list.component';
import { DistributionSiteDetailComponent } from './distribution-site/detail/distribution-site-detail.component';
import { LoadingComponent } from './utility/loading/loading.component';
import { EditorListComponent } from './editor/list/editor-list.component';
import { EditorNewEditComponent } from './editor/new-edit/editor-new-edit.component';
import { EditorDetailComponent } from './editor/detail/editor-detail.component';
import { ErrorComponent } from './utility/error/error.component';
import { DistributorListComponent } from './distributor/list/distributor-list.component';
import { DistributorDetailComponent } from './distributor/detail/distributor-detail.component';
import { DistributorNewEditComponent } from './distributor/new-edit/distributor-new-edit.component';
import { CountryListComponent } from './country/list/country-list.component';
import { CountryDetailComponent } from './country/detail/country-detail.component';
import { CountryNewEditComponent } from './country/new-edit/country-new-edit.component';
import { EditProductComponent } from './product/edit-product/edit-product.component';
import { EditDistributableProductComponent } from './product/edit-distributable-product/edit-distributable-product.component';
import { SubscriptionListComponent } from './subscription/list/subscription-list.component';
import { SubscriptionDetailComponent } from './subscription/detail/subscription-detail.component';
import { CurrencyRateList } from './currency-rate/list/currency-rate-list.component';
import { PaginationComponent } from './utility/pagination/pagination.component';
import { InputComponent } from './utility/form/input/input.component';
import { DateComponent } from './utility/form/date/date.component';
import { SelectComponent } from './utility/form/select/select.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    PaginationComponent,
    InputComponent,
    DateComponent,
    SelectComponent,
    AppComponent,
    ApiRouteDetailComponent,
    ApiRouteListComponent,
    ApiUserDetailComponent,
    ApiUserEditComponent,
    ApiUserListComponent,
    ApiUserNewComponent,
    AuthenticationComponent,
    DistributableProductNewComponent,
    DistributableProductDetailComponent,
    HomeComponent,
    TransactionComponent,
    TransactionEditComponent,
    TransactionDetailComponent,
    SubscriptionListComponent,
    SubscriptionDetailComponent,
    VoucherComponent,
    VoucherDetailComponent,
    VoucherEditComponent,
    ProductDetailComponent,
    ProductListComponent,
    ProductNewComponent,
    PriceListComponent,
    DistributionSiteNewEditComponent,
    DistributionSiteListComponent,
    DistributionSiteDetailComponent,
    LoadingComponent,
    EditorListComponent,
    EditorNewEditComponent,
    EditorDetailComponent,
    ErrorComponent,
    DistributorListComponent,
    DistributorDetailComponent,
    DistributorNewEditComponent,
    CountryListComponent,
    CountryDetailComponent,
    CountryNewEditComponent,
    EditProductComponent,
    EditDistributableProductComponent,
    CurrencyRateList,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ModalModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: jwtTokenGetter,
        skipWhenExpired: true
      }
    }),
    CommonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    NgxPaginationModule,
    NgxDatatableModule,
    BrowserAnimationsModule,
    BsDatepickerModule.forRoot(),
    ReactiveFormsModule,
    FormsModule,
    FontAwesomeModule,
    ChartsModule,
    FileSaverModule
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }, Title, ThemeService, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function jwtTokenGetter() {
  return localStorage.getItem('token');
}
