import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CurrencyRateService {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }

  getCurrencyRate(): Observable<any> {
    return this.http.get(environment.ApiUrl + 'conversionEuro/currency', { headers: this.httpHeaders });
  }

  postCurrencyRate(param): Observable<any> {
    return this.http.post(environment.ApiUrl + 'conversionEuro/currency', param, { headers: this.httpHeaders });
  }
}
