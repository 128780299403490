import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DistributorService {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, private _router: Router) { }

  ngOnInit() { }

  getProductAvailableDistributors(productId) {
    return this.http.get<any>(environment.ApiUrl + 'product/' + productId + '/distributors', { headers: this.httpHeaders });
  }

  getDistributorCountries(distributorId): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'distributor/' + distributorId + '/countries', { headers: this.httpHeaders });
  }

  getDistributorDistributionSitesByCountry(distributorId, countryId): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'distributor/' + distributorId + '/country/' + countryId + '/distribution-sites', { headers: this.httpHeaders });
  }

  getDistributors(params = new HttpParams()): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'distributors?' + params.toString(), { headers: this.httpHeaders });
  }
  getDistributorById(id): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'distributor/' + id, { headers: this.httpHeaders });
  }

  putDistributor(param): Observable<any> {
    return this.http.put<any>(environment.ApiUrl + 'distributor/' + param.id, param, { headers: this.httpHeaders });
  }

  postDistributor(param): Observable<any> {
    return this.http.post<any>(environment.ApiUrl + 'distributor', param, { headers: this.httpHeaders });
  }

}
