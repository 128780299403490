import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ProductService } from '../product/product.service';
import { CountryService } from '../country/country.service';
import { EditorService } from '../editor/editor.service';
import { DistributorService } from '../distributor/distributor.service';
import { TransactionService } from '../transaction/transaction.service';
import { ParameterService } from '../parameter/parameter.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Color, Label } from 'ng2-charts';
import { HttpParams } from "@angular/common/http";
import {
  faCircle, faArrowCircleRight, faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit {

  @ViewChild('productLabelFilter', { static: false }) productLabelFilter: ElementRef;
  @ViewChild('productReferenceFilter', { static: false }) productReferenceFilter: ElementRef;
  @ViewChild('productActiveFilter', { static: false }) productActiveFilter: ElementRef;

  faCircle = faCircle;
  faArrowCircleRight = faArrowCircleRight;
  faExclamationTriangle = faExclamationTriangle;

  loading: boolean = true;
  form: FormGroup;
  datas = [];
  temp = [];
  editors = [];
  distributors = [];
  countries = [];
  voucher_countries = [];
  products = [];
  filtersBy = ['editor', 'distributor', 'country', 'product'];
  filterByValue = "";
  filters = [];
  vouchersFilters = [];
  firstThreshold = undefined;
  secondThreshold = undefined;


  public lineChartOptions: ChartOptions = { legend: { display: false }, responsive: true };
  public lineChartData: ChartDataSets[] = [{ data: [], label: 'Data' }];
  public lineChartLabels: Label[] = [];
  public lineChartType = 'line';
  public lineChartColors: Color[] = [
    {
      borderColor: '#FF6600',
      backgroundColor: '#FF9E73',
    },
  ];

  constructor(private productService: ProductService, private transactionService: TransactionService, private distributorService: DistributorService, private editorService: EditorService, private parameterService: ParameterService, private countryService: CountryService, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      filtersBy: new FormControl(''),
      filter: new FormControl('')
    })
  }


  ngOnInit() {
    this.getVouchersThresholds();
    this.getVouchersKpi();
    this.getTransactionsKpi(this.getSevenDaysAgoDate());
    this.getAvailables();
  }

  getVouchersThresholds() {
    this.parameterService.getVoucherThresholdsParameters().subscribe(
      data => {
        this.firstThreshold = data.details['VCHR_THRESHOLD_ONE'];
        this.secondThreshold = data.details['VCHR_THRESHOLD_TWO'];
      },
      error => { console.log(error); },
      () => { }
    );
  }

  getVouchersKpi() {
    this.productService.getProductVouchersKpi().subscribe(
      data => {
        data.details.sort((a,b) => a.label.localeCompare(b.label));
        this.temp = [...data.details];
        this.datas = data.details;
        let voucher_countries = [];
        for (const data of this.datas) {
          for (const dp of data.distributable_products) {
            if (!voucher_countries.includes(dp.country)){
              voucher_countries.push(dp.country);
            }
          }
        }
        this.voucher_countries = voucher_countries;
        this.updateFilter({target:{value:'true'}},'active');
        this.loading = false;
      },
      error => {
        console.log(error); 
        this.loading = false;
      },
      () => { }
    );
  }

  getTransactionsKpi(date, param = undefined) {
    // var getParams = "beginDate=" + date;
    var getParams = "beginDate=" + date;
    if (param != undefined) {
      var newParam = '&' + this.filterByValue + '=' + param;
      getParams = getParams + newParam;
    }
    this.transactionService.getTransactionsKPI(getParams).subscribe(
      data => {
        this.lineChartLabels = Object.keys(data.details);
        var values = Object.values(data.details);
        this.lineChartData[0].data = values;
      },
      error => { console.log(error); },
      () => { }
    );
  }

  getAvailables() {
    var params = new HttpParams().set('isActive', "true");
    this.editorService.getEditors(params).subscribe(
      data => {
        data.details.forEach(element => {
          this.editors.push({
            'label': element.name,
            'reference': element.reference
          });
        });
      }
    );
    this.distributorService.getDistributors(params).subscribe(
      data => {
        data.details.forEach(element => {
          this.distributors.push({
            'label': element.name,
            'reference': element.reference
          });
        });
      }
    );
    this.countryService.getCountries().subscribe(
      data => {
        data.details.forEach(element => {
          this.countries.push({
            'label': element.name,
            'reference': element.ISOCode
          });
        });
      }
    );
    this.productService.getProducts(params).subscribe(
      data => {
        data.details.forEach(element => {
          this.products.push({
            'label': element.label,
            'reference': element.reference
          });
        });
      }
    );
  }

  fillSelect() {
    this.filterByValue = this.form.value.filtersBy;
    if (this.filterByValue == 'country') {
      this.filters = this.countries;
    }
    else if (this.filterByValue == 'editor') {
      this.filters = this.editors;
    }
    else if (this.filterByValue == 'distributor') {
      this.filters = this.distributors;
    }
    else if (this.filterByValue == 'product') {
      this.filters = this.products;
    }
  }

  filterTransactionsChart() {
    var filter = this.form.value.filter;
    this.getTransactionsKpi(this.getSevenDaysAgoDate(), filter);
  }

  getSevenDaysAgoDate() {
    var sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 6);
    return sevenDaysAgo.toISOString().substr(0, 10);
  }


  updateFilter(event, prop) {

    const value = event.target.value;

    this.vouchersFilters =  this.vouchersFilters.filter(function( obj ) {
      return obj.key !== prop;
    });
    if(value !== ''){
      this.vouchersFilters.push({'key' : prop ,'value':value});
    }

    this.filterData();
  }


  filterData() {

    let temp = this.temp.slice();

    // filtre les données
    for (const filter of this.vouchersFilters) {

      const key = filter.key;
      let value = filter.value;

      temp = temp.filter(rows => {
        value = value.toLowerCase();

        if (key in rows) {
          return rows[key].toString().toLowerCase().indexOf(value) !== -1 || !value;
        } else {
          return !value;
        }
      });
    }

    // Mise à jour des lignes du tableau
    this.datas = temp;
  }

  resetFilter() {
    this.productLabelFilter.nativeElement.value = '';
    this.productReferenceFilter.nativeElement.value = '';
    this.productActiveFilter.nativeElement.value = '';
  }

  clearFilters() {
    this.resetFilter();
    this.datas = this.temp;
  }

  getVouchersbyCountry(row,country_reference) {
    let dps = row.distributable_products.filter(function( dp ) {
      return dp.country === country_reference;
    });
    if(dps.length > 0){
      const min = dps.reduce(function(prev, curr) {
        return prev.available_vouchers < curr.available_vouchers ? prev : curr;
      });
      return min.available_vouchers;
    }else{
      return '';
    }
  }

  hasIcone(row,country_reference,green) {
    let dps = row.distributable_products.filter(function( dp ) {
      return dp.country === country_reference;
    });
    if(dps.length > 0){
      let hasActive = false;

      for (const dp of dps) {
        if(dp.active) hasActive = true;
      }
      if(green) return hasActive;
      else return !hasActive;
    }
    return false;
  }
}
