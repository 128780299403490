import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams } from "@angular/common/http";
import { ApiUserService } from '../api-user.service';
import { ApiRouteService } from '../../api-route/api-route.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import {
  faEdit, faUserTimes, faTimes,
  faArrowAltCircleLeft, faCheck
} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-api-user-edit',
  templateUrl: './api-user-edit.component.html'
})
export class ApiUserEditComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  //icons
  faEdit = faEdit;
  faUserTimes = faUserTimes;
  faTimes = faTimes;
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faCheck = faCheck;

  user = undefined;
  id = undefined;
  wait = 0;
  params = {};
  distributionSites = [];
  routes = [];
  langs = [];

  form: FormGroup;

  constructor(private apiUserService: ApiUserService, private apiRouteService: ApiRouteService,
    private _router: Router, private formBuilder: FormBuilder, private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.form = this.formBuilder.group({
      roles: this.formBuilder.array([])
    });
    this.getApiUser();
    this.getAvailableRoutes();
    this.getLangs();
  }

  getApiUser() {
    this.apiUserService.getApiUserById(this.id).subscribe(
      data => {
        this.getDistributionSites();
        this.user = data.details;
        this.form.addControl('username', new FormControl(this.user.username, [Validators.pattern("^[a-zA-Z0-9_-]{1,250}$")]));
        this.form.addControl('email', new FormControl(this.user.email));
        this.form.addControl('lang', new FormControl(this.user.lang.id));
        this.form.addControl('active', new FormControl(this.user.active));
        this.form.addControl('phoneNumber', new FormControl(this.user.phone_number));
        this.form.addControl('distributionSite', new FormControl(this.user.distribution_site_id));
        this.wait = this.wait + 1;
      },
      error => { console.log(error); },
      () => { }
    );
  }

  getAvailableRoutes() {
    var params = new HttpParams().set('isActive', "true");

    this.apiRouteService.getApiRoutes(params).subscribe(
      data => {
        this.routes = data.details;
        this.wait = this.wait + 1;
      },
      error => { console.log(error); },
      () => { }
    );
  }

  getLangs() {
    this.apiUserService.getLangs().subscribe(
      data => {
        this.langs = data.details;
        this.wait = this.wait + 1;
      },
      error => { console.log(error); },
      () => { }
    );
  }

  getDistributionSites() {
    this.apiUserService.getAvailableDistributionSites().subscribe(
      data => {
        this.distributionSites = data.details;
        this.distributionSites.push({ 'id': this.user.distribution_site_id, 'name': this.user.distribution_site_name });
        this.wait = this.wait + 1;
      },
      error => { console.log(error); },
      () => { }
    );
  }

  onCheckboxChange(e) {
    const roles: FormArray = this.form.get('roles') as FormArray;
    if (e.target.checked) {
      roles.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      roles.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          roles.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  submitForm() {
    this.form.controls.username.markAsDirty();
    var values = this.form.value;
    if (values.active == 'true'){
      values.active = true;
    } else {
      values.active = false;
    }
    this.params = {'username': values.username, 'email': values.email, 'phone_number': values.phoneNumber, 'lang': values.lang, 'active': values.active };
    this.apiUserService.putApiUserById(this.id, this.params).subscribe(
      data => {
        this._router.navigate(['/api-administration/user/' + this.id])
      },
      error => {
        console.log(error);
      },
      () => { }
    );
  }
}
