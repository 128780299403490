import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class VoucherService {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, private router: Router) { }

  getVouchers(params: any = {}): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'vouchers', { params, headers: this.httpHeaders });
  }

  getVoucherStatus(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'voucher/status', { headers: this.httpHeaders });
  }

  getVoucherOrigins(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'voucher/origins', { headers: this.httpHeaders });
  }

  getStocksVouchers(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'vouchers/stock', { headers: this.httpHeaders });
  }

  getEditorsVouchers(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'vouchers/editors', { headers: this.httpHeaders });
  }

  getVoucher(voucherId): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'voucher/' + voucherId, { headers: this.httpHeaders });
  }

  getVoucherCode(voucherId): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'voucher/code/' + voucherId, { headers: this.httpHeaders });
  }

  getAllowedVoucherStatus(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'voucher/allowed-status', { headers: this.httpHeaders });
  }

  postVoucherImportFile(params): Observable<any> {
    return this.http.post<any>(environment.ApiUrl + 'vouchers/import', params);
  }

  putVoucher(voucherId, params): Observable<any> {
    return this.http.put<any>(environment.ApiUrl + 'voucher/' + voucherId, params);
  }

  getCanExportVouchers(params: any = {}): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'vouchers/canExport', {params, headers: this.httpHeaders });
  }

  exportVouchers(params: any = {}): Observable<any> {
    return this.http.get(environment.ApiUrl + 'vouchers/export', {
      params,
      headers: this.httpHeaders,
      responseType: 'blob',
    });
  }
}
