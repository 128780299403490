import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, private _router: Router) { }

  ngOnInit() { }

  getSubscriptions(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'subscriptions', { headers: this.httpHeaders });
  }

  getSubscriptionById(subscriptionId: string): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'subscription/' + subscriptionId, { headers: this.httpHeaders });
  }

  exportSubscrptions(filters) {
    return this.http.get(environment.ApiUrl + 'subscriptions/export' + filters, { headers: this.httpHeaders, responseType: 'blob' });
  }
}