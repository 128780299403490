import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';

import { ProductService } from '../product.service';


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html'
})
export class ProductListComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('labelFilter', { static: false }) labelFilter: ElementRef;
  @ViewChild('typeFilter', { static: false }) typeFilter: ElementRef;
  @ViewChild('editorFilter', { static: false }) editorFilter: ElementRef;
  @ViewChild('activeFilter', { static: false }) activeFilter: ElementRef;

  loading: boolean = true;
  products = [];
  temp = [];

  // Tableaux des options possibles des selects pour la recherche
  types = [];
  editors = [];
  actives = [];
  filters = [];

  // Gestion nombre de lignes par page
  productsPerPageOptions = [5, 10, 20, 50, 100];
  productsPerPage = 5;

  constructor(private productService: ProductService) { }

  ngOnInit() {
    this.getProducts();
  }

  getProducts() {
    this.productService.getProducts().subscribe(
        data => {
          this.temp = [...data.details];
          this.products = data.details;
          this.loading = false;
          this.fillSelects();
        },
        error => { 
          console.log(error);
          this.loading = false;
        },
        () => { }
    );
  }

  fillSelects() {
    this.types = this.products
        .map(item => item.product_type)
        .filter((value, index, self) => self.indexOf(value) === index);

    this.editors = this.products
        .map(item => item.editor_name)
        .filter((value, index, self) => self.indexOf(value) === index);

    this.actives = this.products
        .map(item => item.is_active)
        .filter((value, index, self) => self.indexOf(value) === index);
  }

  changeProductsPerPage(value) {
    this.productsPerPage = value;
  }

  updateFilter(event, prop) {
    const existingIndex = this.filters.findIndex(item => item.key === prop);
    const value = event.target.value;

    if (value !== '') {
      if (existingIndex !== -1) {
        this.filters[existingIndex].value = value;
      } else {
        this.filters.push({'key': prop, 'value': value, 'type': ''});
      }
    } else {
      if (existingIndex !== -1) {
        this.filters.splice(existingIndex, 1);
      }
    }

    const temp = this.temp.filter(rows => {
      return this.filters.every(filter => {
        if (rows.hasOwnProperty(filter.key) && rows[filter.key] !== null) {
          return rows[filter.key].toString().toLowerCase().indexOf(filter.value.toLowerCase()) !== -1 || !filter.value;
        } else {
          return !filter.value;
        }
      });
    });

    this.products = temp;
    this.table.offset = 0;
  }

  resetFilter() {
    this.labelFilter.nativeElement.value = '';
    this.typeFilter.nativeElement.value = '';
    this.editorFilter.nativeElement.value = '';
    this.activeFilter.nativeElement.value = '';
  }

  clearFilters() {
    this.resetFilter();
    this.filters = [];
    this.products = this.temp;
    this.table.offset = 0;
  }
}
