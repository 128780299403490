import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiRouteService {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, private _router: Router) { }

  ngOnInit() { }

  getApiRoutes(params = new HttpParams()): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'api-routes?' + params.toString(), { headers: this.httpHeaders });
  }

  getApiRoute(id): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'api-route/' + id, { headers: this.httpHeaders });
  }

  getApiRouteApiCalls(id): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + "api-route/" + id + "/api-calls", { headers: this.httpHeaders });
  }
}
