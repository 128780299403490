import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiUserService } from '../api-user.service';
import { ApiRouteService } from '../../api-route/api-route.service';
import { EditorService } from '../../../editor/editor.service';

import {
  faEdit, faUserTimes, faUserCheck,
  faArrowAltCircleLeft, faCheckCircle,
  faTimesCircle, faTimes, faCheck,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import { element } from 'protractor';

@Component({
  selector: 'app-api-user-detail',
  templateUrl: './api-user-detail.component.html'
})
export class ApiUserDetailComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  //icons
  faEdit = faEdit;
  faUserTimes = faUserTimes;
  faUserCheck = faUserCheck;
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  faTimes = faTimes;
  faCheck = faCheck;
  faEnvelope = faEnvelope;

  mouseOverButtonLogin = false;
  mouseOverButtonEmail = false;
  user = undefined;
  services = undefined;
  unknownServices = [];
  displayUnknownServices = false;
  calls = undefined;
  allowedServicesRole = [];
  newServicesAllowed = [];
  id = undefined;
  wait = 0;
  isEdit = false;
  distributionSites = [];
  editorList = [];
  unknown = [];

  allowedRoutes = {};

  constructor(private apiUserService: ApiUserService, private apiRouteService: ApiRouteService,
    private _router: Router, private route: ActivatedRoute, private editorService: EditorService) { }

  ngOnInit() {
    this.wait = 0;
    this.id = this.route.snapshot.paramMap.get('id');
    this.getUserInfos();
    this.getApiCalls();
    this.getDistributionSites();
    this.getEditor();
  }

  // Api User informations + User Route Infos
  getUserInfos() {
    this.apiUserService.getApiUserRouteInformations(this.id).subscribe(
      data => {
        this.user = data.details.user;
        let routes = data.details.apiRoutes;
        Object.keys(data.details.apiRoutes).forEach(key => {
          let route = {
            'api_route_id': routes[key].apiRoute.id,
            'distribution_site_id': routes[key].distributionSite != null ? routes[key].distributionSite.id : null,
            'editor_id': routes[key].editor != null ? routes[key].editor.id : null,
            'is_allowed': routes[key].isAllowed,
            'has_to_have_DS': routes[key].apiRoute.isWithDistributionSite,
            'has_to_have_Editor': routes[key].apiRoute.isWithEditor
          }
          this.allowedRoutes[routes[key].apiRoute.code] = route;
        });
        this.wait = this.wait + 1;

      },
      error => { console.log(error); },
      () => { }
    );
  }

  getApiCalls() {
    this.apiUserService.getApiUserApiCalls(this.id).subscribe(
      data => {
        this.calls = data.details;
        this.wait = this.wait + 2;
      },
      error => { console.log(error); },
      () => { }
    );
  }

  getDistributionSites() {
    this.apiUserService.getAvailableDistributionSites().subscribe(
      data => {
        this.distributionSites = data.details;
        this.wait = this.wait + 1;
      },
      error => { console.log(error); },
      () => { }
    );
  }

  getEditor() {
    this.editorService.getEditors().subscribe((datas) => {
      this.editorList = datas.details;
    }, (error) => {
      console.log(error);
    })
  }

  updateAllowedServices() {
    let errors = false;
    Object.keys(this.allowedRoutes).forEach(key => {
      if (this.allowedRoutes[key].has_to_have_DS && this.allowedRoutes[key].is_allowed && (this.allowedRoutes[key].distribution_site_id == null || this.allowedRoutes[key].distribution_site_id == '')) {
        errors = true;
      }
      if (this.allowedRoutes[key].has_to_have_Editor && this.allowedRoutes[key].is_allowed && (this.allowedRoutes[key].editor_id == null || this.allowedRoutes[key].editor_id == '')) {
        errors = true;
      }
    });
    if (!errors) {
      this.isEdit = false;
      this.apiUserService.updateApiUserRoutes(this.id, this.allowedRoutes).subscribe(
        data => {
          this.ngOnInit()
        },
        errors => { this.isEdit = true; console.log(errors) }
      )
    }
  }

  changeDsAllowedRoutes(event, row) {
    this.allowedRoutes[row.code].distribution_site_id = event.target.value;
  }

  changeEditorAllowedRoutes(event, row) {
    this.allowedRoutes[row.code].editor_id = event.target.value;
  }


  resetPassword() {
    this.apiUserService.putApiUserResetPassword(this.id).subscribe(
      data => { },
      error => { console.log(error); },
      () => { }
    );
  }

  changeApiUserActivation() {

    if (this.user.active) {
      var confirmation = confirm("Are you sure you want to deactivate this user?");
    } else {
      var confirmation = confirm("Are you sure you want to activate this user?");
    }
    if (confirmation) {
      this.user.active = !this.user.active;
      var params = this.user;
      this.apiUserService.putApiUserById(this.id, params).subscribe(
        data => {
          this.ngOnInit();
        },
        error => { console.log(error); },
        () => { }
      );
    }
  }
}
