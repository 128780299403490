import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DistributionSiteService } from '../distribution-site.service';
import { DistributorService } from '../../distributor/distributor.service';
import { Router, ActivatedRoute } from '@angular/router';
import { faArrowAltCircleLeft, faTimes, faCheck, faPlusCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { DistributionSite } from '../DistributionSite';
import { FormBuilder, FormArray, FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'app-distribution-site-new-edit',
  templateUrl: './distribution-site-new-edit.component.html',
  styleUrls: ['./distribution-site-new-edit.component.css']
})
export class DistributionSiteNewEditComponent implements OnInit {

  //icons
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faPlusCircle = faPlusCircle;
  faTimesCircle = faTimesCircle;
  faTimes = faTimes;
  faCheck = faCheck;

  // Form
  id: number;
  dsForm: FormGroup;
  countries: Array<any>;
  langs: Array<any>;
  distributors: Array<any>;
  paymentMeans: Array<any>;
  paymentMeansParametersType = {};

  loading = true;

  constructor(
    private distributionSiteService: DistributionSiteService,
    private distributorService: DistributorService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder) {
    // Form skeleton
    this.dsForm = this.formBuilder.group({
      reference: ['', [Validators.required, Validators.maxLength(32), Validators.pattern('^[0-9a-zA-Z-_]{1,32}$')]],
      name: ['', [Validators.required, Validators.maxLength(255)]],
      // Country and Distributor seront enabled une fois les options des selects récupérées
      country: [{ value: '', disabled: true }, [Validators.required]],
      distributor: [{ value: '', disabled: true }, [Validators.required]],
      sendingSMS: [false, [Validators.required]],
      sendSMSFromDate: [''],
      isActive: [true, [Validators.required]],
      modeInApp: [false, [Validators.required]],
      paymentMeans: this.formBuilder.array([]),
      notifications: this.formBuilder.array([]),
    });
  }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.getSelectsInfos();
    this.getPaymentMeans();
    this.getNotifications();
    // On récupérera les données du site de distribution après getNotifications pour éviter les erreurs de formulaires
  }

  getSelectsInfos() {
    // Countries
    this.distributionSiteService.getCountries().subscribe(data => {
      this.countries = data.details
      this.dsForm.controls.country.enable();
    })
    // Active distributors
    var params = new HttpParams().set('isActive', "true");
    this.distributorService.getDistributors(params).subscribe(data => {
      this.distributors = data.details
      this.dsForm.controls.distributor.enable();
    })
    // Langs
    this.distributionSiteService.getLangs().subscribe(data => {
      this.langs = data.details;
    })
  }

  getPaymentMeans() {
    this.distributionSiteService.getPaymentMeans().subscribe(
      data => {
        // On garde les infos en mémoire, si on active un moyen de paiement, on ajoute à la volée les Validators.required sur les paramètres (cf managePaymentMeanActivation)
        this.paymentMeans = data.details;
        let pmFormArray = this.dsForm.get('paymentMeans') as FormArray;
        // On ajoute chaque FormGroup PaymentMean au FormArray prévu
        data.details.forEach(
          pm => {
            let newPmGroup = this.formBuilder.group({
              label: [pm.label],
              reference: [pm.reference],
              isActivated: [false],
              parameters: this.formBuilder.group({})
            });
            pmFormArray.push(newPmGroup);
          }
        )
      })
  }

  getNotifications() {
    this.distributionSiteService.getNotifications().subscribe(
      data => {
        let notifFormArray = this.dsForm.get('notifications') as FormArray;
        // On ajoute chaque FormGroup Notification au FormArray prévu
        data.details.forEach(
          notif => {
            let newNotifGroup = this.formBuilder.group({
              code: [notif.code],
              isActivated: [false],
              recipients: this.formBuilder.array([])
            });
            notifFormArray.push(newNotifGroup);
          }
        )
      },
      error => { },
      () => {
        if (this.id) {
          this.getDistributionSite();
        }
        else {
          this.loading = false;
        }
      }
    )
  }

  getDistributionSite() {
    this.distributionSiteService.getDistributionSiteById(this.id).subscribe(
      data => {
        // On établit les différentes valeurs du site de distribution
        this.dsForm.patchValue({
          ...data.details.distribution_site,
          name: data.details.distribution_site.name,
          reference: data.details.distribution_site.reference,
          distributor: data.details.distribution_site.distributor.id,
          country: data.details.distribution_site.country.id,
          sendingSMS: data.details.distribution_site.sendingSMS,
          modeInApp: data.details.distribution_site.isInapp,
          sendSMSFromDate: new Date(data.details.distribution_site.sendSMSFromDate)
        });
        data.details.payment_means.forEach(
          pm => {
            // On ajoute le formGroup moyen de paiement vide au formulaire
            this.managePaymentMeanActivation(pm.pm_reference, true);
            let pmArray = this.dsForm.get('paymentMeans') as FormArray;
            pmArray.controls.forEach(
              paymentMean => {
                if (pm.pm_reference == paymentMean.value.reference) {
                  let pmGroup = paymentMean as FormGroup;
                  pmGroup.get('isActivated').setValue(true);
                  let paramGroup = pmGroup.get('parameters') as FormGroup;
                  // Pour chaque paramètre, on set la valeur
                  Object.keys(pm.parameters).forEach(
                    key => {
                      if (paramGroup.controls[key] !== undefined) {
                        paramGroup.controls[key].setValue(pm.parameters[key]);
                      }
                    }
                  )
                }
              })
          }
        );
        data.details.notifications.forEach(
          dsNotif => {
            let notifArray = this.dsForm.get('notifications') as FormArray;
            notifArray.controls.forEach(
              notif => {
                let notifGroup = notif as FormGroup;
                let rcpArray = notif.get('recipients') as FormArray;
                if (notif.value.code == dsNotif.code) {
                  notifGroup.get('isActivated').setValue(true);
                  dsNotif.recipients.forEach(
                    recipient => {
                      rcpArray.push(this.formBuilder.group({
                        email: [recipient.email],
                        lang: [recipient.lang],
                      }))
                    }
                  )
                }
              }
            )
          })
      },
      error => {
        this.router.navigate(['/distribution-sites']);
      },
      () => {
        this.loading = false;
      }
    );
  }

  managePaymentMeanActivation(pmRef, toAdd = true) {
    let pmArray = this.dsForm.get('paymentMeans') as FormArray;
    pmArray.controls.forEach(
      paymentMean => {
        if (pmRef == paymentMean.value.reference) {
          let pmGroup = paymentMean as FormGroup;
          pmGroup.get('isActivated').setValue(toAdd);
          let paramGroup = pmGroup.get('parameters') as FormGroup;
          this.paymentMeans.forEach(
            pm => {
              if (pm.label == paymentMean.get('label').value) {
                // On ajoute ou on supprime les paramètres et ses validateurs lorsqu'on active/désactive le moyen de paiement
                let tempParamType = {};
                pm.parameters.forEach(
                  param => {
                    tempParamType[param.name] = param.type;
                    if (toAdd) {
                      let validators = [];
                      if (param.size) {
                        validators.push(Validators.maxLength(param.size));
                      }
                      if (!param.nullable) {
                        validators.push(Validators.required);
                      }
                      paramGroup.addControl(param.name, this.formBuilder.control('', validators));
                    }
                    else {
                      paramGroup.removeControl(param.name);
                    }
                  }
                );
                this.paymentMeansParametersType[pm.reference] = tempParamType;
              }
            }
          )
        }
      }
    )
  }

  manageNotificationRecipient(notifIdx, toAdd, rcpIdx = null) {
    let notifArray = this.dsForm.get('notifications') as FormArray;
    let notif = notifArray.controls[notifIdx];
    let notifGroup = notif as FormGroup;
    let rcpArray = notif.get('recipients') as FormArray;
    if (toAdd) {
      rcpArray.push(this.formBuilder.group({
        email: [''],
        lang: [''],
      }))
    }
    else if (!toAdd && rcpIdx != null) {
      rcpArray.removeAt(rcpIdx);
    }
  }

  markFormDirty() {
      Object.keys( this.dsForm.controls).forEach(key => {
          this.dsForm.controls[key].markAsDirty();
      });

      let pmArray = this.dsForm.get('paymentMeans') as FormArray;
      pmArray.controls.forEach(
          paymentMean => {
              let pmGroup = paymentMean as FormGroup;
              let paramGroup = pmGroup.get('parameters') as FormGroup;
              Object.keys(paramGroup.controls).forEach(key => {
                  paramGroup.controls[key].markAsDirty();
              });
          });
  }

  submitAction() {

    this.markFormDirty();
    if (this.dsForm.valid) {
      if (this.id) {
        this.distributionSiteService.putDistributionSites(this.id, this.dsForm.value).subscribe(
          (result) => {
            this.router.navigate(['distribution-site', result.details.distribution_site.id]);
          },
          error => {
            this.loading = false;
            console.log(error);
          })
      }
      else {
        this.distributionSiteService.postDistributionSites(this.dsForm.value).subscribe((result) => {
          this.router.navigate(['distribution-site', result.details.distribution_site.id]);
        },
          error => {
            this.loading = false;
            console.log(error);
          })
      }
    }
  }
}
