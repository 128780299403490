import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SelectComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label: string;
  @Input() options: Array<any>;
  @Input() emptyOption = false;
  @Input() change: (event: any) => {};
  
  constructor() { }

  ngOnInit(): void {
  }

  onChange = (event) => {
    if (this.change) { this.change(event); }
  }

}
