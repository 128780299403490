import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ActivatedRoute } from '@angular/router';
import { VoucherService } from '../voucher.service';
import { faEdit, faArrowAltCircleLeft, faCheckCircle, faTimesCircle, faTimes, faCheck, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-voucher-detail',
  templateUrl: './voucher-detail.component.html'
})
export class VoucherDetailComponent implements OnInit {
    
    @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
    
    //icons
    faEdit = faEdit;
    faArrowAltCircleLeft = faArrowAltCircleLeft;
    faCheckCircle = faCheckCircle;
    faTimesCircle = faTimesCircle;
    faTimes = faTimes;
    faCheck = faCheck;
    faPlusCircle = faPlusCircle;

    id = undefined;
    voucher = undefined;
    wait = true;
    code = 0;
    codeFound = false;
    countdown = 11;

    constructor(private voucherService: VoucherService, private route: ActivatedRoute){ }

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('id');
        this.getVoucher();
    }

    getVoucher(){
      this.voucherService.getVoucher(this.id).subscribe(
        data => {
          this.voucher = data.details;
          this.wait = false;

          //if the array return by the backend have only empty value, 
          //then making it as an empty array instead of an array with 1 element which is {element.value: null and element.key: null }
          if(this.voucher.voucherMetas.length>0 && this.voucher.voucherMetas[0].key==null && this.voucher.voucherMetas[0].value==null){
            this.voucher.voucherMetas.splice(0,1);
          }
        },
        error => {console.log(error);},
        () => { }
      )
    }

    getVoucherCode(){
      this.voucherService.getVoucherCode(this.id).subscribe(
        data => {
          this.code = data.details.code;
          this.codeFound = true;
        },
        error => {console.log(error);},
        () => { }
      )
    }

    private async revealSecretCode(){
      
      this.code = 1;
      this.getVoucherCode();

      while(this.countdown>=0){
        await this.sleep(1000);
        this.countdown = this.countdown - 1;
      }
      this.countdown = 11;
      this.code = 0;
      this.codeFound=false;
    }

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

}
