import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class InputComponent implements OnInit, AfterViewChecked {

  @Input() control: FormControl;
  @Input() label: string;
  @Input() type = 'text';
  @Input() change: (event: any) => {};

  constructor( private changeDetector: ChangeDetectorRef) { }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnInit(): void {}

  onChange = (event): void => {
    this.control.updateValueAndValidity();
    if (this.change) {
      this.change(event);
    }
  }
}
