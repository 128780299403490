import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthenticationGuard } from './authentication.guard';

import { ApiRouteDetailComponent } from './api-administration/api-route/detail/api-route-detail.component';
import { ApiRouteListComponent } from './api-administration/api-route/list/api-route-list.component';
import { ApiUserDetailComponent } from './api-administration/api-user/detail/api-user-detail.component';
import { ApiUserEditComponent } from './api-administration/api-user/edit/api-user-edit.component';
import { ApiUserListComponent } from './api-administration/api-user/list/api-user-list.component';
import { ApiUserNewComponent } from './api-administration/api-user/new/api-user-new.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { DistributableProductNewComponent } from './product/new-distributable-product/distributable-product-new.component';
import { DistributableProductDetailComponent } from './product/detail-distributable-product/distributable-product-detail.component';
import { HomeComponent } from './home/home.component';
import { ProductDetailComponent } from './product/detail/product-detail.component';
import { ProductListComponent } from './product/list/product-list.component';
import { ProductNewComponent } from './product/new-product/product-new.component';
import { PriceListComponent } from './price/list/price-list.component';
import { TransactionComponent } from './transaction/list/transaction-list.component';
import { TransactionEditComponent } from './transaction/edit/transaction-edit.component';
import { TransactionDetailComponent } from './transaction/detail/transaction-detail.component';
import { VoucherComponent } from './voucher/list/voucher-list.component';
import { VoucherDetailComponent } from './voucher/detail/voucher-detail.component';
import { VoucherEditComponent } from './voucher/edit/voucher-edit.component';
import { DistributionSiteNewEditComponent } from './distribution-site/new-edit/distribution-site-new-edit.component';
import { DistributionSiteListComponent } from './distribution-site/list/distribution-site-list.component';
import { DistributionSiteDetailComponent } from './distribution-site/detail/distribution-site-detail.component';
import { EditorListComponent } from './editor/list/editor-list.component';
import { EditorNewEditComponent } from './editor/new-edit/editor-new-edit.component';
import { EditorDetailComponent } from './editor/detail/editor-detail.component';
import { DistributorListComponent } from './distributor/list/distributor-list.component';
import { DistributorDetailComponent } from './distributor/detail/distributor-detail.component';
import { DistributorNewEditComponent } from './distributor/new-edit/distributor-new-edit.component';
import { CountryListComponent } from './country/list/country-list.component';
import { CountryDetailComponent } from './country/detail/country-detail.component';
import { CountryNewEditComponent } from './country/new-edit/country-new-edit.component';
import { EditProductComponent } from "./product/edit-product/edit-product.component";
import { EditDistributableProductComponent } from "./product/edit-distributable-product/edit-distributable-product.component";
import { SubscriptionListComponent } from './subscription/list/subscription-list.component';
import { SubscriptionDetailComponent } from './subscription/detail/subscription-detail.component';
import { CurrencyRateList } from './currency-rate/list/currency-rate-list.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full', canActivate: [AuthenticationGuard] },
  { path: 'login', component: AuthenticationComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthenticationGuard] },
  { path: 'products', component: ProductListComponent, canActivate: [AuthenticationGuard] },
  { path: 'product/new', component: ProductNewComponent, canActivate: [AuthenticationGuard] },
  { path: 'product/:id', component: ProductDetailComponent, canActivate: [AuthenticationGuard] },
  { path: 'product/:id/edit', component: EditProductComponent, canActivate: [AuthenticationGuard] },
  { path: 'product/:id/new-distributable-product', component: DistributableProductNewComponent, canActivate: [AuthenticationGuard] },
  { path: 'product/:id/detail-distributable-product/:id-dist', component: DistributableProductDetailComponent, canActivate: [AuthenticationGuard] },
  { path: 'product/:id/detail-distributable-product/:id-dist/edit', component: EditDistributableProductComponent, canActivate: [AuthenticationGuard] },
  { path: 'prices', component: PriceListComponent, canActivate: [AuthenticationGuard]},
  { path: 'transactions', component: TransactionComponent, canActivate: [AuthenticationGuard] },
  { path: 'transaction/:id', component: TransactionDetailComponent, canActivate: [AuthenticationGuard] },
  { path: 'transaction/edit/:id', component: TransactionEditComponent, canActivate: [AuthenticationGuard] },
  { path: 'subscriptions', component: SubscriptionListComponent, canActivate: [AuthenticationGuard] },
  { path: 'subscription/:id', component: SubscriptionDetailComponent, canActivate: [AuthenticationGuard] },
  { path: 'vouchers', component: VoucherComponent, canActivate: [AuthenticationGuard] },
  { path: 'voucher/:id', component: VoucherDetailComponent, canActivate: [AuthenticationGuard] },
  { path: 'voucher/:id/edit', component: VoucherEditComponent, canActivate: [AuthenticationGuard] },
  { path: 'api-administration/services', component: ApiRouteListComponent, canActivate: [AuthenticationGuard] },
  { path: 'api-administration/service/:id', component: ApiRouteDetailComponent, canActivate: [AuthenticationGuard] },
  { path: 'api-administration/users', component: ApiUserListComponent, canActivate: [AuthenticationGuard] },
  { path: 'api-administration/user/new', component: ApiUserNewComponent, canActivate: [AuthenticationGuard] },
  { path: 'api-administration/user/:id', component: ApiUserDetailComponent, canActivate: [AuthenticationGuard] },
  { path: 'api-administration/user/:id/edit', component: ApiUserEditComponent, canActivate: [AuthenticationGuard] },
  { path: 'distribution-site/:id/edit', component: DistributionSiteNewEditComponent, canActivate: [AuthenticationGuard] },
  { path: 'distribution-site/new', component: DistributionSiteNewEditComponent, canActivate: [AuthenticationGuard] },
  { path: 'distribution-site/:id', component: DistributionSiteDetailComponent, canActivate: [AuthenticationGuard] },
  { path: 'distribution-sites', component: DistributionSiteListComponent, canActivate: [AuthenticationGuard] },
  { path: 'editors', component: EditorListComponent, canActivate: [AuthenticationGuard] },
  { path: 'editor/:id/edit', component: EditorNewEditComponent, canActivate: [AuthenticationGuard] },
  { path: 'editor/new', component: EditorNewEditComponent, canActivate: [AuthenticationGuard] },
  { path: 'editor/:id', component: EditorDetailComponent, canActivate: [AuthenticationGuard] },
  { path: 'distributors', component: DistributorListComponent, canActivate: [AuthenticationGuard] },
  { path: 'distributor/new', component: DistributorNewEditComponent, canActivate: [AuthenticationGuard] },
  { path: 'distributor/:id', component: DistributorDetailComponent, canActivate: [AuthenticationGuard] },
  { path: 'distributor/:id/edit', component: DistributorNewEditComponent, canActivate: [AuthenticationGuard] },
  { path: 'countries', component: CountryListComponent, canActivate: [AuthenticationGuard] },
  { path: 'country/new', component: CountryNewEditComponent, canActivate: [AuthenticationGuard] },
  { path: 'country/:id', component: CountryDetailComponent, canActivate: [AuthenticationGuard] },
  { path: 'country/:id/edit', component: CountryNewEditComponent, canActivate: [AuthenticationGuard] },
  { path: 'currency-rate', component: CurrencyRateList, canActivate: [AuthenticationGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
