import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class PriceService {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, private _router: Router) { }

  ngOnInit() { }

  getActivePrices(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'distributable-product-price/active', { headers: this.httpHeaders });
  }

  putPrices(params): Observable<any> {
    return this.http.put<any>(environment.ApiUrl + 'distributable-product-prices', params, { headers: this.httpHeaders });
  }

  exportActivePrices(filters: string): Observable<any> {
    return this.http.get(environment.ApiUrl + 'distributable-product-price/export/active' + filters, { headers: this.httpHeaders, responseType: 'blob' });
  }
}
