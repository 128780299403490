import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  //icons
  faPowerOff = faPowerOff;

  title = 'Orange Link - Aggregator backoffice';

  constructor(public router: Router, translate: TranslateService, private titleService: Title) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    if(localStorage.getItem('lang')){
      translate.use(localStorage.getItem('lang'));
    }

    this.titleService.setTitle(this.title);
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['login']);
  }
}
