import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {observable, throwError as observableThrowError,Observable} from 'rxjs';
import { Router } from '@angular/router';

import { AuthenticationService } from './authentication.service';
import { catchError } from 'rxjs/operators';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService, private _router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (this.authenticationService.isLoggedIn()) {
            var token = this.authenticationService.getToken();
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        return next.handle(request).pipe(catchError(err => {
            if(err.status === 401) {
                this.authenticationService.clearLocalStorage();
                this._router.navigate(['login']);
            } else if (err.status === 500) {
                this.authenticationService.clearLocalStorage();
                this._router.navigate(['login']);
            }
            return observableThrowError(err)
        })

        );
    }
}
