import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, private _router: Router) { }

  ngOnInit() { }

  loginCredentials(username, password): Observable<Object> {
    return this.http.post<Object>(environment.ApiUrl + "login/credentials", { 'username': username, 'password': password }, { headers: this.httpHeaders });
  }

  loginAuthCode(username, authCode): Observable<Object> {
    return this.http.post<Object>(environment.ApiUrl + "login/auth-code", { 'username': username, 'auth_code': authCode }, { headers: this.httpHeaders });
  }

  isLoggedIn() {
    return this.getToken() != null;
  }

  setToken = (token) => {
    localStorage.setItem('token', token);
  }

  getToken = () => {
    return localStorage.getItem('token');
  }

  clearLocalStorage = () => {
    localStorage.clear();
  }
}
