import { Editor } from '../editor/Editor';

export class Distributor {

    public id: number;
    
    public reference: string;

    public name: string;

    public isActive: boolean;

    public editors: Editor[];

    public constructor() {
        this.id = null;
        this.reference = '';
        this.name = '';
        this.isActive = true;
        this.editors = [];
    }



}