import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, PipeTransform, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PaginationComponent implements OnInit {
  @Input() request;
  @Input() columns;
  @Input() actions;
  @Input() size: string;
  @Input() updateDataCallback: (data: any[]) => any[];
  @Input() initialPredicate: string = null;
  @Input() pageSizeOptions: Array<number> = [10, 25, 50, 100];
  datasGetter: Subject<any> = new Subject<any>();
  paginationInfo: Subject<any> = new Subject<any>();
  @Output() getDatas: EventEmitter<Subject<any>> = new EventEmitter<Subject<any>>();
  @Output() setResult: EventEmitter<Subject<any>> = new EventEmitter<Subject<any>>();
  @Output() setPagination: EventEmitter<any> = new EventEmitter<any>();

  loadingCount = 0;
  initialLoading = true;

  page = {
    size : 0,
    totalElements: 0,
    totalPages: 0,
    pageNumber: 0
  }

  datas: Array<any> = [];
  filter: any = {};
  predicate = '';
  sort = false;
  sortOrder = '';

  constructor(private router: Router, private changeDetectorRef: ChangeDetectorRef) {
    this.page.pageNumber = 0;
    this.page.size = 10;

    this.datasGetter.subscribe(filter => {
      if (filter.filter) { this.filter = filter.filter; }
      if (filter.predicate) { this.predicate = filter.predicate; }
      if (filter.sort !== undefined) { this.sort = filter.sort; }
      this.updateDatas();
    });
  }

  ngOnInit(): void {
    this.getDatas.emit(this.datasGetter);
    this.emitPaginationInfos();
    
    if (this.initialPredicate) {
      this.sort = true;
      this.predicate = this.initialPredicate;
      this.updateDatas();
    }
  }

  setSort(sortInfo) {
    this.predicate = sortInfo.column.prop;
    this.sort = sortInfo.sorts[0].dir;
    this.updateDatas();
  }

  setPage(pageInfo) {
    this.page.pageNumber = pageInfo.offset;
    this.updateDatas();
  }

  changeElementPerPage(elementPerPage) {
    this.page.size = elementPerPage;
    this.updateDatas();
  }

  updateDatas = (): void => {
    this.loadingCount++;
    
    if (this.page.pageNumber < 0) {
      this.page.pageNumber = 0;
    }

    this.datas.length = 0;
    
    this.request({
      filter: JSON.stringify(this.filter),
      page: this.page.pageNumber + 1,
      per_page: this.page.size,
      predicate: this.predicate,
      sort: this.sort,
    })
      .toPromise()
      .then(response => {
        if (!this.initialLoading) { this.initialLoading = false; }
        this.loadingCount--;
        this.page.pageNumber = Number(response.details.pagination.page) - 1;
        this.page.totalPages = response.details.pagination.total;
        this.page.totalElements = response.details.pagination.totalEntries;
        Array.prototype.push.apply(this.datas, response.details.data);

        if (this.updateDataCallback !== undefined) {
          this.datas = this.updateDataCallback(this.datas);
        }
        
        this.setResult.emit(response);
      })
      .catch(error => {
        this.loadingCount--;
    });
  }

  emitPaginationInfos = (): void => {
    const params = {
      filter: JSON.stringify(this.filter),
      predicate: this.predicate,
      page: this.page.pageNumber + 1,
      pageSize: this.page.size,
      sort: this.sort
    };
  
    this.paginationInfo.next(params);
    this.setPagination.emit(params);
  }
}
