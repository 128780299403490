import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { Router } from '@angular/router';
import { translateTemplates } from '@swimlane/ngx-datatable';
import { TranslateService } from '@ngx-translate/core';
import { faCircleNotch, faRedo, faSpinner } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication.component.html',
  styleUrls: ['./authentication.component.css']
})
export class AuthenticationComponent implements OnInit {

  //icon
  faRedo = faRedo;
  faSpinner = faSpinner;
  faCircleNotch = faCircleNotch;

  public errorMessage;
  public lang;
  public searching = false;
  public username;

  public showAuthenticationCodeForm = false;

  constructor(private authenticationService: AuthenticationService, private translate: TranslateService, private _router: Router) {
  }

  ngOnInit() {

  }

  loginCredentials(username, password) {
    this.searching = true;
    this.authenticationService.loginCredentials(username, password).subscribe(
      data => {
        this.errorMessage = "";
        this.showAuthenticationCodeForm = true;
        this.username = data['details']['username'];
        this.searching = false;
      },
      error => {
        if (error.status == 401) {
          this.errorMessage = "Wrong credentials. Try another login or password. This account may be blocked due to too many attempts.";
          this.searching = false;
        }
      });
  }

  loginWithAuthCode(authCode) {
    this.searching = true;
    this.authenticationService.loginAuthCode(this.username, authCode).subscribe(
      data => {
        this.authenticationService.setToken(data['details']['token']);
        this._router.navigate(['home']);
        this.searching = false;
      },
      error => {
        if (error.status == 401) {
          this.errorMessage = "Wrong authentication code. Try another authentication code. This account may be blocked due to too many attempts.";
          this.searching = false;
        }
      });
  }
}
