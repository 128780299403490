import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { FileSaverService } from 'ngx-filesaver';
import { DatePipe } from '@angular/common'
import { getDate } from 'ngx-bootstrap/chronos/utils/date-getters';
import { SubscriptionService } from '../subscription.service';
import { ActivatedRoute } from '@angular/router';
import { faEdit, faArrowAltCircleLeft, faCheckCircle, faTimesCircle, faTimes, faCheck, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-subscription-detail',
  templateUrl: './subscription-detail.component.html'
})
export class SubscriptionDetailComponent implements OnInit {

  //icons
  faEdit = faEdit;
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  faTimes = faTimes;
  faCheck = faCheck;
  faPlusCircle = faPlusCircle;

  id = undefined;
  wait = true;
  subscription = null;
  transactions = [];

  constructor(private subscriptionService: SubscriptionService, public datepipe: DatePipe, private route: ActivatedRoute, private translate: TranslateService) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.getSubscription(this.id);
  }

  getSubscription(id: string) {
    this.subscriptionService.getSubscriptionById(id).subscribe((result : any) => {
      this.subscription = result.details.subscription;
      this.transactions = result.details.transactions;
      this.wait = false;
    })
  }

  parseJSON(json: any) {
    return JSON.parse(json);
  }

  getTransactionType(type: string) {
    return type !== 'ABORP' ? 'PRODUCT.TYPE.Subscription_Purchase' : 'PRODUCT.TYPE.Subscription_Renewal';
  }

  getSubscriptionType(type: string) {
    return type !== 'ABO1' ? 'SUBSCRIPTION.TYPE.Renewable' : 'SUBSCRIPTION.TYPE.One_shot';
  }
}
