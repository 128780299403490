import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../product.service';
import { faTimes,  faEdit, faArrowAltCircleLeft, faCheck} from '@fortawesome/free-solid-svg-icons';
import { DatatableComponent } from '@swimlane/ngx-datatable';

@Component({
  selector: 'app-distributable-product-new',
  templateUrl: './distributable-product-detail.component.html',
  styleUrls: ["./distributable-product-detail.component.css"]
})
export class DistributableProductDetailComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('startingDateFilter', { static: false }) startingDateFilter: ElementRef;
  @ViewChild('endingDateFilter', { static: false }) endingDateFilter: ElementRef;
  @ViewChild('preTaxValueFilter', { static: false }) preTaxValueFilter: ElementRef;
  @ViewChild('inclusiveTaxValueFilter', { static: false }) inclusiveTaxValueFilter: ElementRef;
  @ViewChild('promoPreTaxValueFilter', { static: false }) promoPreTaxValueFilter: ElementRef;
  @ViewChild('promoInclusiveTaxValueFilter', { static: false }) promoInclusiveTaxValueFilter: ElementRef;
  //icons
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faTimes = faTimes;
  faCheck = faCheck;
  faEdit = faEdit;

  distributableId = undefined;
  distributableProduct = undefined;
  distributableProductPrice = undefined;
  distributableProductPriceHistory = [];
  distributableProductPriceHistoryTemp = [];
  distributableProductPriceHistoryPerPageOptions = [5, 10, 20, 50, 100];
  distributableProductPriceHistoryPerPage = 5;
  distributableProductPriceHistoryFilters = [];

  product = undefined;
  wait = 0;

  loading = false;

  TYPE_ONE_TIME_SUBSCRIPTION;
  TYPE_RECURRING_SUBSCRIPTION;

  constructor(private productService: ProductService, private route: ActivatedRoute) {
    this.TYPE_ONE_TIME_SUBSCRIPTION = productService.TYPE_ONE_TIME_SUBSCRIPTION;
    this.TYPE_RECURRING_SUBSCRIPTION = productService.TYPE_RECURRING_SUBSCRIPTION;
  }

  ngOnInit() {
    this.distributableId = this.route.snapshot.paramMap.get('id-dist');
    this.getDistributableProduct(this.distributableId);
    this.getActiveDistributableProductPrice(this.distributableId);
    this.getDistributableProductPriceHistory(this.distributableId);
  }

  getDistributableProduct(distributableId) {
    this.productService.getDistributableProduct(distributableId).subscribe(
        data => {
          this.distributableProduct = data.details;
          this.product = data.details.product;
          this.wait = this.wait + 1;
        },
        error => { console.log(error); },
        () => { }
    );
  }

  getActiveDistributableProductPrice(distributableId) {
    this.productService.getActiveDistributableProductPriceByDistributableProduct(distributableId).subscribe(
      data => {
        this.distributableProductPrice = data.details;
        this.wait = this.wait + 1;
      },
      error => { console.log(error); },
      () => { }
    );
  }

  getDistributableProductPriceHistory(distributableId) {
    this.productService.getDistributableProductPriceHistoryByDistributableProduct(distributableId).subscribe(
      data => {
        this.distributableProductPriceHistoryTemp = [...data.details];
        this.distributableProductPriceHistory = data.details;
        this.wait = this.wait + 1;
      },
      error => { console.log(error); },
      () => { }
    );
  }

  changeDistributableProductPriceHistoryPerPage(value) {
    this.distributableProductPriceHistoryPerPage = value;
  }

  updateDateFilter(value, prop) {

    this.distributableProductPriceHistoryFilters =  this.distributableProductPriceHistoryFilters.filter(function( obj ) {
      return obj.key !== prop;
    });
    if(value != null){
      this.distributableProductPriceHistoryFilters.push({'key' : prop, 'value':value, 'type':'date'});
    }

    this.filterData();
  }

  updateFilter(event, prop) {

    const value = event.target.value;

    this.distributableProductPriceHistoryFilters = this.distributableProductPriceHistoryFilters.filter(function( obj ) {
      return obj.key !== prop;
    });
    if(value != ''){
      this.distributableProductPriceHistoryFilters.push({'key' : prop ,'value':value,'type':''});
    }

    this.filterData();
  }

  filterData() {

    let temp = this.distributableProductPriceHistoryTemp.slice();

    // filtre les données
    for (const filter of this.distributableProductPriceHistoryFilters) {

      const key = filter.key;
      let value = filter.value;
      let type = filter.type;

      temp = temp.filter(rows => {
        if (type == 'date') {
          if (!value) {
            return !value;
          } else {
            const dateDebut = value.setHours(0, 0, 0);
            const dateFin = value.setHours(23, 59, 59);
            const tempDate = new Date(rows[key]);

            return tempDate > dateDebut && tempDate < dateFin || !value;
          }
        }else{
          value = value.toLowerCase();
          if (rows[key]) {
            return rows[key].toString().toLowerCase().indexOf(value) !== -1 || !value;
          } else {
            return !value;
          }
        }
      });
    }

    // Mise à jour des lignes du tableau
    this.distributableProductPriceHistory = temp;
    // Rétourner à la première page de la pagination si il y a eu un filtre
    this.table.offset = 0;
  }

  resetFilter() {
    this.startingDateFilter.nativeElement.value = '';
    this.endingDateFilter.nativeElement.value = '';
    this.preTaxValueFilter.nativeElement.value = '';
    this.inclusiveTaxValueFilter.nativeElement.value = '';
    this.promoInclusiveTaxValueFilter.nativeElement.value = '';
    this.promoPreTaxValueFilter.nativeElement.value = '';
  }

  clearFilters() {
    this.resetFilter();
    this.distributableProductPriceHistory = this.distributableProductPriceHistoryTemp;
    this.distributableProductPriceHistoryFilters = [];
    this.table.offset = 0;
  }
}
