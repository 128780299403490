import { Component, OnInit, ViewChild, ElementRef, Type, TemplateRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { TransactionService } from '../transaction.service';
import { FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule} from "@angular/forms";
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-transaction-edit',
  templateUrl: './transaction-edit.component.html'
})
export class TransactionEditComponent implements OnInit {

    @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

    //icons
    faTimes = faTimes;
    faCheck = faCheck;

    id = undefined;
    wait = true;
    transaction = undefined;

    formEditTransaction: FormGroup;
    statusTransactionList = [{label: "Canceled", code: "CANCELED"}];
    modalRef: BsModalRef;

    constructor(private transactionService: TransactionService,
      private route: ActivatedRoute,
      private formBuilder: FormBuilder,
      private modalService: BsModalService,
      private router: Router
    ) {
      this.formEditTransaction = this.formBuilder.group({
        status: new FormControl('', [Validators.required]),
        comment: [null],
      });
    }

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('id');
        this.getTransaction();
    }

    getTransaction(){
      this.transactionService.getTransaction(this.id).subscribe(
        data => {
          this.transaction = data.details;
          if (this.transaction.status !== "Rfc")
            this.router.navigate(['/transaction', this.id]);
          this.wait = false;
        },
        error => {console.log(error);},
        () => { }
      )

    }

    openModal(template: TemplateRef<any>): void {
      if (this.formEditTransaction.get('status').value === '') {
        this.formEditTransaction.controls['status'].setErrors({'missing_value': true});
        return;
      }
      this.modalRef = this.modalService.show(template, {
        animated: true,
        backdrop: 'static'
      });
    }

    saveTransaction(): void {
      const params = {
        'idTransaction' : this.id,
        'transactionStatus' : this.formEditTransaction.get('status').value,
        'transactionComment' : this.formEditTransaction.get('comment').value,
      }

      this.transactionService.postTransactionStatus(params).subscribe((response) => {
        this.router.navigate(['/transaction', this.id]);
      }, (error) => {
        console.log(error);
      })
    }

}
