import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.css']
})
export class DateComponent implements OnInit {

  @Input() control: FormControl;
  @Input() label: string;
  @Input() change = (event: any) => { };
  faCalendar = faCalendar;

  constructor() { }

  ngOnInit(): void {}

  onChange = (event): void => {
    this.control.updateValueAndValidity();
    if (event.value && ((typeof event.value._i === 'string' && event.value._i.length === 10) || typeof event.value._i === 'object') || event.value === null) {
      this.change(event);
    }
  }
}
