import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { VoucherService } from '../voucher.service';
import { ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common'
import { FileSaverService } from 'ngx-filesaver';
import { faArrowAltCircleUp, faPlusCircle, faCircleNotch, faFileUpload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { EditorService } from 'src/app/editor/editor.service';
import { CountryService } from 'src/app/country/country.service';

@Component({
  selector: 'app-voucher-list',
  templateUrl: './voucher-list.component.html',
  styleUrls: ["./voucher-list.component.css"]
})
export class VoucherComponent implements OnInit {
  datasUpdater: Subject<any>;
  datas: any;
  paginationParams: any;
  columns: Array<any> = [
    { label: 'VOUCHER.Editor', definition: 'editor', type: 'text', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'TRANSACTION.Product', definition: 'product', type: 'text', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'VOUCHER.serialNumber', definition: 'serialNumber', type: 'text', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'VOUCHER.Unique_ID', definition: 'id', type: 'link', sortable: true, link : { url :'/voucher/' , title : 'TEMPLATE.LIST.See_Details'}, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'VOUCHER.Countries', definition: 'countries', type: 'array', arrayOption: { translateConcat : true, translatePrefix : 'COUNTRY.'}, sortable: false, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'VOUCHER.Currency', definition: 'currency', type: 'text', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'VOUCHER.Buying_Price', definition: 'buyingPrice', type: 'text', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'VOUCHER.Buying_Price_EUR', definition: 'buyingPriceEur', type: 'text', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'VOUCHER.Creation_Date', definition: 'creationDate', type: 'date', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'VOUCHER.Expiration_Date', definition: 'expiryDate', type: 'date', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'VOUCHER.Status', definition: 'status', type: 'text-translate', translatePrefix: 'VOUCHER.STATUS.', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
    { label: 'VOUCHER.Origin', definition: 'origin', type: 'text-translate', translatePrefix: 'VOUCHER.ORIGIN.', sortable: true, style: {resizeable: false, flexGrow: 1, minWidth: 75 }},
  ]
  actions: Array<any> = [];

  pageSizeOptions: Array<number> = [5, 10, 25, 50, 100];

  faArrowAltCircleUp = faArrowAltCircleUp;
  faPlusCircle = faPlusCircle;
  faCircleNotch = faCircleNotch;
  faFileUpload = faFileUpload;
  faTimes = faTimes;

  editors = [];
  countries = [];
  status = [];
  origins = [];


  showUpload = false;
  importWait = false;
  voucherFile = undefined;
  importResponse = undefined;

  modalRef: BsModalRef;
  exportAsked: boolean = false;
  form: FormGroup;
  formVoucher: FormGroup;
  errorMessageExport = "";
  exportLimitExceeded: boolean = false;
  loadingExport: boolean = false;

  constructor(
    private voucherService: VoucherService, 
    private route: ActivatedRoute,
    private fileSaverService: FileSaverService,
    private formBuilder: FormBuilder,
    public datepipe: DatePipe,
    private modalService: BsModalService,
    private editorService: EditorService,
    private countryService: CountryService) {
    this.form = this.formBuilder.group({
      code: new FormControl('', [Validators.required]),
    })

    this.formVoucher = this.formBuilder.group({
      editor: [null],
      product: [null],
      serialNumber: [null],
      id: [null],
      country: [null],
      currency: [null],
      buyingPrice: [null],
      buyingPriceEUR: [null],
      createdAt: [null],
      expiryDate: [null],
      status: [null],
      origin: [null],

    })
   }

  ngOnInit() {
    this.editorService.getEditors().subscribe((response: any) => {
      this.editors = response.details.map(element => ({ name: element.name, value: element.id }));
    });

    this.countryService.getCountries().subscribe((response : any) => {
      this.countries = response.details.map(element => ({ name: element.name, value: element.id }));
    });

    this.voucherService.getVoucherStatus().subscribe((response : any) => {
      this.status = response.details.map(element => ({ name: element.label, value: element.id }));
    });

    this.voucherService.getVoucherOrigins().subscribe((response: any) => {
      this.origins = response.details.map(element => ({ name: element.code, value: element.id }));
    });
  }

  initData = (event: Subject<any>): void => {
    this.datasUpdater = event;
    this.datasUpdater.next({ filter: {}, predicate:'creationDate', sort: 'desc' });
  }

  getData = (data) => {
    this.datas = data.data;
  }

  getRequest = (param: any = {}): Observable<any> => {
    return this.voucherService.getVouchers(param);
  }

  getPaginationInfo = (info) => {
    this.paginationParams = info;
  }

  updateData = (event: any = null) => {
    this.datasUpdater.next({ ...this.paginationParams, filter: this.getFiltersValues() });
  }

  getFiltersValues = () => {

    let dateTo:Date = null;
    let dateFrom:Date = null;

    if (this.formVoucher.get('createdAt').value) {
      dateFrom = this.formVoucher.get('createdAt').value[0];
      dateFrom.setHours(dateFrom.getHours() - dateFrom.getTimezoneOffset() / 60);

      dateTo = this.formVoucher.get('createdAt').value[1];
      dateTo.setHours(dateTo.getHours() - dateTo.getTimezoneOffset() / 60);
      this.formVoucher.get('createdAt').setValue([dateFrom, dateTo])
    }
    
    if (this.formVoucher.get('expiryDate').value) {
      dateFrom= this.formVoucher.get('expiryDate').value[0];
      dateFrom.setHours(dateFrom.getHours() - dateFrom.getTimezoneOffset() / 60);

      dateTo = this.formVoucher.get('expiryDate').value[1];
      dateTo.setHours(dateTo.getHours() - dateTo.getTimezoneOffset() / 60);
      this.formVoucher.get('expiryDate').setValue([dateFrom, dateTo])
    }

    const filters: any = Object.keys(this.formVoucher.value)
      .filter((k) => this.formVoucher.value[k] !== null && this.formVoucher.value[k] !== '')
      .reduce((a, k) => ({ ...a, [k]: this.formVoucher.value[k] }), {});
    return filters;
  }

  clearFilters() {
    this.formVoucher.reset();
    this.updateData();
  }

  uploadFile(event) {
    const file = (event.target as HTMLInputElement).files[0];
    this.voucherFile = file;
  }

  submitFile() {
    this.showInputUpload();
    var $allowedExt = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    if ($allowedExt.includes(this.voucherFile.type)) {
      var formData = new FormData();
      formData.append('voucher_file', this.voucherFile);
      this.importWait = true;
      this.voucherService.postVoucherImportFile(formData).subscribe(
        data => {
          this.importWait = false;
          this.showUploadStatus(data.details.code);
          this.ngOnInit();
        },
        error => {
          this.importWait = false;
          this.showUploadStatus(error.details.code);
        },
        () => { }
      );
    }
    else {
      this.showUploadStatus('ERR_EXTENSION');
    }
  }

  showInputUpload() {
    this.showUpload = !this.showUpload;
  }

  showUploadStatus(msg) {
    this.importResponse = msg;
    setTimeout(() => {
      this.importResponse = undefined;
    }, 30000)
  }

  openModalExportVoucher(template: TemplateRef<any>): void {
    this.exportLimitExceeded = false;
    this.exportAsked = false;
    this.errorMessageExport = "";
    this.form.get("code").setValue("");

    this.voucherService.getCanExportVouchers({filter : JSON.stringify(this.getFiltersValues())}).subscribe(
      data => {
        this.modalRef = this.modalService.show(template, {
          animated: true,
          backdrop: 'static'
        });
        this.loadingExport = true;
        this.voucherService.exportVouchers({generateExportCode: true}).subscribe(
          data => {
            this.exportAsked = true;
            this.loadingExport = false;
          },
          error => { 
            this.loadingExport = false;
            this.modalRef.hide();
          },
          () => {}
        );
      }, 
      error => {
        if (error.error.details.code === "EXPORT_LIMIT_EXCEEDED") {
          this.exportLimitExceeded = true;
        }
      }
    );
  }

  exportVouchers() {
    if (!this.exportAsked) {
      return;
    }
    if (!this.form.valid) {
      this.errorMessageExport = "Please complete this field";
      return;
    }
    this.loadingExport = true;
    this.voucherService.exportVouchers({code: this.form.get("code").value, filter : JSON.stringify(this.getFiltersValues())}).subscribe(
      data => {
        var filename = "Export Vouchers OLink agg " + this.datepipe.transform(new Date(), 'ddMMyyyy');
        this.fileSaverService.save(data, filename + '.csv');
        this.loadingExport = false;
        this.modalRef.hide();
      },
      error => {
        if (error.status = 400) {
          this.errorMessageExport = "Wrong code. Try another code."
        }
        this.loadingExport = false;
        console.log(error); 
      },
      () => { }
    );
  }
}
