import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DistributionSiteService } from '../distribution-site.service';
import { DistributionSite } from '../DistributionSite';
import { faEdit, faArrowAltCircleLeft, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-detail',
  templateUrl: './distribution-site-detail.component.html',
  styleUrls: ['./distribution-site-detail.component.css']
})
export class DistributionSiteDetailComponent implements OnInit {

  distributionSite: DistributionSite = new DistributionSite();
  paymentMeans = [];
  notifications = [];
  calls = [];
  id: number;
  loading: boolean = true;
  loadingStat: boolean = true;
  error: string = null;

  // Icons 
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faEdit = faEdit;
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;
  // Form
  form: FormGroup;

  constructor(
    private distributionSiteService: DistributionSiteService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      editor: new FormControl('',),
      reference: new FormControl(''),
      label: new FormControl(''),
      type: new FormControl(''),
      subscription: new FormControl(''),
      active: new FormControl(''),
      recurrence: new FormControl('')
    })
  }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    this.getSiteDistribution(this.id);
    this.getStatsByDistributionSiteId(this.id);
  }

  getSiteDistribution(id) {
    if (id) {
      this.distributionSiteService.getDistributionSiteById(id).subscribe(
        data => {
          this.distributionSite = data.details.distribution_site;
          this.paymentMeans = data.details.payment_means;
          this.notifications = data.details.notifications;
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.error = error.statusText;
        })
    }
  }

  getStatsByDistributionSiteId(id) {
    if (id) {
      this.distributionSiteService.getStatsByDistributionSiteId(id).subscribe(
        data => {
          this.calls = data.details;
          this.loadingStat = false;
        },
        error => {
          this.loading = false;
          this.loadingStat = false;
          this.error = error.statusText;
        })
    }
  }

  changeIsActive() {
    this.loading = true;
    this.distributionSite.isActive = !this.distributionSite.isActive;
    this.distributionSiteService.changeStatusDistributionSites(this.id, this.distributionSite).subscribe(result => {
      this.distributionSite = result.details.distribution_site;
      this.paymentMeans = result.details.payment_means;
      this.notifications = result.details.notifications;
      this.loading = false;
      this.error = null;
    }, error => {
      this.error = error.statusText;
      this.loading = false;
    })
  }
}
