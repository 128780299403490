import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Router } from '@angular/router';
import { HttpParams } from "@angular/common/http";
import { ApiUserService } from '../api-user.service';
import { ApiRouteService } from '../../api-route/api-route.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { EditorService } from '../../../editor/editor.service';
import {
  faEdit, faUserTimes, faTimes,
  faArrowAltCircleLeft, faCheck, faTimesCircle,
  faCheckCircle
} from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-api-user-new',
  templateUrl: './api-user-new.component.html'
})
export class ApiUserNewComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

  //icons
  faEdit = faEdit;
  faUserTimes = faUserTimes;
  faTimes = faTimes;
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faCheck = faCheck;
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;

  user = undefined;
  id = undefined;
  wait = 0;
  params = {};
  distributionSites = [];
  editorList = [];
  services = [];
  langs = [];
  newServicesAllowed = [];

  errorUsername = false;
  errorEmail = false;
  errorPhoneNumber = false;
  errorDistributionSite = false;
  errorLang = false;
  errorActive = false;
  errorForm = false;

  form: FormGroup;

  constructor(private apiUserService: ApiUserService, private apiRouteService: ApiRouteService,
    private _router: Router, private formBuilder: FormBuilder, private editorService: EditorService) {
    this.form = this.formBuilder.group({
      roles: this.formBuilder.array([]),
      distributionSite: new FormControl(''),
      editor: new FormControl(''),
      username: new FormControl('', [Validators.pattern('^[0-9a-zA-Z_-]{1,250}$')]),
      phoneNumber: new FormControl(''),
      email: new FormControl(''),
      lang: new FormControl(''),
      active: new FormControl('')
    })
  }

  ngOnInit() {
    this.getAvailableRoutes();
    this.getDistributionSites();
    this.getLangs();
    this.getEditor();
  }

  getAvailableRoutes() {
    var params = new HttpParams().set('isActive', "true");

    this.apiRouteService.getApiRoutes(params).subscribe(
      data => {
        this.services = data.details;
        this.services.forEach( element => {
          element.distributionSite = '';
          element.editor = '';
        });
        this.wait = this.wait + 1;
      },
      error => { console.log(error); },
      () => { }
    );
  }

  getDistributionSites() {
    this.apiUserService.getAvailableDistributionSites().subscribe(
      data => {
        this.distributionSites = data.details;
        this.wait = this.wait + 1;
      },
      error => { console.log(error); },
      () => { }
    );
  }

  getLangs() {
    this.apiUserService.getLangs().subscribe(
      data => {
        this.langs = data.details;
        this.wait = this.wait + 1;
      },
      error => { console.log(error); },
      () => { }
    );
  }

  getEditor() {
    this.editorService.getEditors().subscribe((datas) => {
      this.editorList = datas.details;
    }, (error) => {
      console.log(error);
    })
  }

  addAllowedRoutes(service, bool) {
    if (bool == true) {
      this.newServicesAllowed.push(service);
    }
    else {
      let indexOfElement = -1;
      let index = 0;
      this.newServicesAllowed.forEach(element => {
        if (element.id == service.id) {
          indexOfElement = index;
        }
        index = index + 1;
      });
      if (indexOfElement > -1) {
        this.newServicesAllowed.splice(indexOfElement, 1);
      }
    }
  }

  compareToAllowedServicesRole(service) {
    let toReturn = false;
    this.newServicesAllowed.forEach(element => {
      if (element.code === service.code) {
        toReturn = true;
      }
    });
    return toReturn;
  }

  compareToNewServicesAllowed(service) {
    let toReturn = false;
    this.newServicesAllowed.forEach(element => {
      if (element.code === service.code) {
        toReturn = true;
      }
    });
    return toReturn;
  }

  isWithDistributionSite(service) {
    return service.isWithDistributionSite;
  }

  isWithEditor(service) {
    return service.isWithEditor;
  }

  checkForm() {
    var values = this.form.value;
    if ((values.username).length == 0) {
      this.errorUsername = true;
      this.errorForm = true;
    }
    if ((values.email).length == 0) {
      this.errorEmail = true;
      this.errorForm = true;
    }
    if ((values.phoneNumber).length == 0) {
      this.errorPhoneNumber = true;
      this.errorForm = true;
    }
    if ((values.lang).length == 0) {
      this.errorLang = true;
      this.errorForm = true;
    }
    if ((values.active).length == 0) {
      this.errorActive = true;
      this.errorForm = true;
    }
  }

  updateAllowedServices(id) {
    // we add the distribution site that is linked with the road
      var params = { 'routes': this.newServicesAllowed };
      this.apiUserService.changeAllowedRoutesForUser(id, params).subscribe(
        data => {
          this.ngOnInit();
        },
        error => {
          console.log(error);
        }, () => { }
      );
  }

  submitForm() {
    let isSubmittable = true;
    this.newServicesAllowed.forEach( element => {
      if (element.distributionSite == '' && element.isWithDistributionSite ){
        isSubmittable = false;
      }
      if (element.editor == '' && element.isWithEditor ){
        isSubmittable = false;
      }
    })
    if (!isSubmittable)
      return;

    this.form.controls.username.markAsDirty();
    var values = this.form.value;
    this.errorForm = false;
    this.checkForm();
    if ((this.errorForm == false) && isSubmittable) {
      if (values.active == 'true') {
        values.active = true;
      } else {
        values.active = false;
      }
      this.wait = this.wait - 1;
      this.params = { 'roles': this.newServicesAllowed, 'username': values.username, 'email': values.email, "phone_number": values.phoneNumber, 'lang': values.lang, 'active': values.active };
      //this.updateAllowedServices("data.details.id");
      this.apiUserService.postNewApiUser(this.params).subscribe(
        data => {
          this.updateAllowedServices(data.details.id);
          this._router.navigate(['/api-administration/user/' + data.details.id])
          this.wait = this.wait + 1;
        },
        error => {
          console.log(error);
        },
        () => { }
      );
    }
  }
}
