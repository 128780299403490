import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ApiUserService } from '../api-user.service';

@Component({
  selector: 'app-api-user-list',
  templateUrl: './api-user-list.component.html'
})
export class ApiUserListComponent implements OnInit {

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  @ViewChild('referenceFilter', {static: false}) referenceFilter: ElementRef;
  @ViewChild('emailFilter', {static: false}) emailFilter: ElementRef;

  loading: boolean = true;
  datas = [];
  temp = [];
  objectKeys = Object.keys;

  filters = [];
  sortBy = 'createdAt';
  sortOrder = 'desc';

  // Tableaux des options possibles des selects pour la recherche
  types = [];

  // Gestion nombre de lignes par page
  apiUsersPerPageOptions = [5, 10, 20, 50, 100];
  apiUsersPerPage = 5;

  constructor(private apiUserService: ApiUserService) { }

  ngOnInit() {
    this.getApiUsers();
  }

  getApiUsers() {
    this.apiUserService.getApiUsers().subscribe(
      data => {
        this.temp = [...data.details];
        this.datas = data.details;
        this.loading = false;
        this.fillSelects();
      },
      error => {
        console.log(error); 
        this.loading = false;
      },
      () => { }
    );
  }

  fillSelects() {
    this.types = this.datas
      .map(item => item.type)
      .filter((value, index, self) => self.indexOf(value) === index);
  }

  changeApiUsersPerPage(value) {
    this.apiUsersPerPage = value;
  }

  updateFilter(event, prop) {
    // Vider tous les champs de recherche sans perdre le champs courant
    const tempQuery = event.target.value;
    this.resetFilter();
    event.target.value = tempQuery;

    const query = event.target.value.toLowerCase();

    // filtre les données
    const temp = this.temp.filter(rows => {
      if (rows[prop]) {
        return rows[prop].toString().toLowerCase().indexOf(query) !== -1 || !query;
      } else {
        return !query;
      }
    });

    this.datas = temp;
    this.table.offset = 0;
  }

  resetFilter() {
    this.referenceFilter.nativeElement.value = '';
    this.emailFilter.nativeElement.value = '';
  }

  clearFilters() {
    this.resetFilter();
    this.datas = this.temp;
    this.table.offset = 0;
  }
}
