import { Component, OnInit, ViewChild, ElementRef, asNativeElements } from '@angular/core';
import { CountryService } from '../country.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Country } from '../Country';
import { faEdit, faArrowAltCircleLeft, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';



@Component({
  selector: 'app-country-detail',
  templateUrl: './country-detail.component.html',
})

export class CountryDetailComponent implements OnInit {

  loading: boolean = true;
  error: string = null;
  country:Country = new Country();
  id: number;

  // Icons
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faEdit = faEdit;
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;

  // Item number per page
  countriesPerPageOptions: number[] = [5, 10, 20, 50, 100];
  countriesPerPage: number = 5;
  constructor(
    private countryService: CountryService,
    private route: ActivatedRoute,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params.id;
    this.getCountry(this.id);
  }

  getCountry(id) {
    if (id) {
      this.countryService.getCountryById(id).subscribe(data => {
        this.loading = false;
        this.error = null;
        this.country = data.details;
      },
        error => {
          this.error = error.statusText;
          this.loading = false;
        }
      );
    }
  }

  changenCountriesPerPage(value) {
    this.countriesPerPage = value;
  }

  changeIsActive() {
    this.loading = true;
    let newCountry = { ...this.country, isActive: !this.country.isActive };
    this.countryService.updateCountry(newCountry).subscribe(result => {
      this.country = result.details;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

}
