import { Component, OnInit, ViewChild, ElementRef, Type, TemplateRef } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { ActivatedRoute } from '@angular/router';
import { TransactionService } from '../transaction.service';
import { FormGroup, FormBuilder, Validators, FormControl, ReactiveFormsModule} from "@angular/forms";
import { BsModalService, BsModalRef } from 'ngx-bootstrap';
import { faEdit, faArrowAltCircleLeft, faCheckCircle, faTimesCircle, faTimes, faCheck, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
type ProductType = Array<{Type : string, DistributableProductReference: string, ProductReference: string, VATAmount: number, PreTaxValue : number, InclusiveTaxValue: number, Quantity: number, Editor: string, Vouchers: Array<string>}>;

@Component({
  selector: 'app-transaction-detail',
  templateUrl: './transaction-detail.component.html'
})
export class TransactionDetailComponent implements OnInit {

    @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;

    //icons
    faEdit = faEdit;
    faArrowAltCircleLeft = faArrowAltCircleLeft;
    faCheckCircle = faCheckCircle;
    faTimesCircle = faTimesCircle;
    faTimes = faTimes;
    faCheck = faCheck;
    faPlusCircle = faPlusCircle;

    id = undefined;
    transaction = undefined;
    wait = true;
    isVoucher=false;
    ditributableProducts : [] = undefined;
    products : ProductType = [];
    voucherReservations = [];
    editTransaction = false;

    constructor(private transactionService: TransactionService,
      private route: ActivatedRoute,
      private formBuilder: FormBuilder,
      private modalService: BsModalService
    ) {
    }

    ngOnInit(): void {
        this.id = this.route.snapshot.paramMap.get('id');
        this.getTransaction();
    }

    getTransaction(){
      this.transactionService.getTransaction(this.id).subscribe(
        data => {
          this.transaction = data.details;

          if (this.transaction.status === "Rfc")
            this.editTransaction = true;

          this.wait = false;
          let vouchers = [];
          //If there is many product linked to a transaction, we collect all the informations here
          for (let jsonProductindex = 0; jsonProductindex < this.transaction.jsonProducts.length; jsonProductindex++) {
            //parsing the json attribute to get all the information about the distributable product
            const ditributableProduct = JSON.parse(this.transaction.jsonProducts[jsonProductindex].jsonProduct);
            //parsing the json to get all the infromation of the product. This json was inside the json of the distributable product
            const product = JSON.parse(ditributableProduct.jsonProduct)
            if (product.type = "voucher"){
              this.isVoucher = true;
            }
            for (let voucherIndex = 0; voucherIndex < this.transaction.vouchers.length; voucherIndex++){
              if (this.transaction.jsonProducts[jsonProductindex].trlid == this.transaction.vouchers[voucherIndex].trlid){
                vouchers.push(this.transaction.vouchers[voucherIndex].voucherid);
              }
            }

            //putting all the information in a tab that will be display for the front end part
            this.products.push({
              Type : ditributableProduct.product.type.label,
              DistributableProductReference: ditributableProduct.reference,
              ProductReference: ditributableProduct.product.reference,
              VATAmount: this.transaction.jsonProducts[jsonProductindex].VATRate,
              PreTaxValue : this.transaction.jsonProducts[jsonProductindex].preTaxValuetrl,
              InclusiveTaxValue: this.transaction.jsonProducts[jsonProductindex].inclusiveTaxValuetrl,
              Quantity:this.transaction.jsonProducts[jsonProductindex].quantity,
              Editor: product.editor,
              Vouchers:vouchers
            })
            vouchers=[];

            this.voucherReservations = this.transaction.voucherReservations
              .map((voucherReservation) => ({
                id: voucherReservation.voucherReservationId,
                origin: voucherReservation.origin,
                reserveId: voucherReservation.reserveId,
                buyingPrice: voucherReservation.buyingPrice,
                currency: voucherReservation.currency,
              }));
          }
        },
        error => {console.log(error);},
        () => { }
      )

    }

}
