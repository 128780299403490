import { Component, OnInit } from '@angular/core';
import { CountryService } from '../country.service';
import { Router, ActivatedRoute } from '@angular/router';
import { faEdit, faArrowAltCircleLeft, faCheckCircle, faTimesCircle, faTimes, faCheck, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { Country } from '../Country';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';


@Component({
  selector: 'country-new-edit',
  templateUrl: './country-new-edit.component.html',
})
export class CountryNewEditComponent implements OnInit {

  id = null;
  error = null;
  loading = true;
  country:Country = new Country();
  langs = [];
  billingTypes = [];

  //icons
  faEdit = faEdit;
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faCheckCircle = faCheckCircle;
  faTimesCircle = faTimesCircle;
  faTimes = faTimes;
  faCheck = faCheck;
  faPlusCircle = faPlusCircle;

  form: FormGroup;

  constructor(
    private countryService: CountryService,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(255)
      ]),
      ISOCode: new FormControl('', [
        Validators.required,
        Validators.maxLength(3),
        Validators.pattern("^[a-zA-Z0-9_-]{1,3}$")
      ]),
      OrangeMCOCode: new FormControl('', [
        Validators.maxLength(3)
      ]),
      currency: new FormControl('', [
        Validators.required,
        Validators.maxLength(10)
      ]),
      lang: new FormControl(''),
      billingType: new FormControl(''),
      commissionRate: new FormControl(''),
      isActive: new FormControl(true, Validators.required),
    })
  }

  ngOnInit() {
    this.id = this.route.snapshot.params.id;
    if (this.id) {
      this.getCountry(this.id);
    } else {
      this.loading = false;
    }
    this.countryService.getLangs().subscribe(data => {
      this.langs = data.details;
    });

    this.countryService.getBillingTypes().subscribe(data => {
      this.billingTypes = data.details;
    });
  }

  getCountry(id) {
    if (id) {
      this.countryService.getCountryById(id).subscribe(data => {
        this.loading = false;
        if (data.details) {
          this.form.patchValue({
            ...data.details,
            name: data.details.name,
            currency: data.details.currency,
            ISOCode: data.details.ISOCode,
            lang: data.details.lang ? data.details.lang.id : '',
            billingType: data.details.billingType ? data.details.billingType.id : '',
            commissionRate: data.details.commissionRate ? data.details.commissionRate : '',
          })
        } else { this.router.navigate(['/countries']);}
      },
        error => {
          this.loading = false;
          this.error = error.statusText;
        })
    }
  }

  submitAction() {
    this.form.controls.name.markAsDirty();
    this.form.controls.currency.markAsDirty();
    this.form.controls.ISOCode.markAsDirty();
    this.form.controls.OrangeMCOCode.markAsDirty();

    if (this.form.valid) {
      this.loading = true;
      let country = {
        name: this.form.value.name,
        currency: this.form.value.currency,
        ISOCode: this.form.value.ISOCode,
        OrangeMCOCode: this.form.value.OrangeMCOCode,
        lang: this.form.value.lang,
        billingType: this.form.value.billingType,
        id: this.id,
        isActive: this.form.value.isActive === 'true' || this.form.value.isActive === true ? true : false,
        commissionRate: this.billingTypeIsCommission(this.form.value.billingType) ? this.form.value.commissionRate : '',
      }
      this.loading = false;
      if (this.id) {
        this.updateCountry(country)
      } else {
        this.createCountry(country)
      }
    } else {
      this.error = 'TEMPLATE.FORM.Invalid';
    }

  }

  updateCountry(country){
    this.countryService.updateCountry(country).subscribe(data => {
      this.router.navigate(['/country', data.details.id]);
    }, error => {
      this.error = error.statusText;
    });
  }

  createCountry(country){
    this.countryService.createCountry(country).subscribe(data => {
      this.router.navigate(['/country', data.details.id]);
    });
  }

  navigateToDetail(id) {
    this.router.navigate(['country', `${id}`])
  }

  billingTypeIsCommission(billingTypeId): boolean {
    return !!this.billingTypes.find(({ id, code }) => id == billingTypeId && code === 'COM');
  }
}
