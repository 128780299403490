import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ApiUserService {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient, private _router: Router) { }

  ngOnInit() { }

  getApiUsers(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'api-users', { headers: this.httpHeaders });
  }

  getAvailableDistributionSites(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'distribution-sites/available', { headers: this.httpHeaders });
  }

  getLangs(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'langs', { headers: this.httpHeaders });
  }

  getApiUserById(id): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'api-user/' + id, { headers: this.httpHeaders });
  }

  getApiUserRouteInformations(id): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'api-user-route/' + id, { headers: this.httpHeaders });
  }

  getApiUserApiCalls(id): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'api-user/' + id + '/api-calls', { headers: this.httpHeaders });
  }

  postNewApiUser(params): Observable<any> {
    return this.http.post<any>(environment.ApiUrl + 'api-user', params, { headers: this.httpHeaders });
  }

  putApiUserById(id, params): Observable<any> {
    return this.http.put<any>(environment.ApiUrl + 'api-user/' + id, params, { headers: this.httpHeaders });
  }

  putApiUserResetPassword(id): Observable<any> {
    return this.http.put<any>(environment.ApiUrl + 'api-user/' + id + '/reset-password', {}, { headers: this.httpHeaders });
  }

  deleteApiUser(id): Observable<any> {
    return this.http.delete<any>(environment.ApiUrl + 'api-user/' + id, { headers: this.httpHeaders });
  }

  changeAllowedRoutesForUser(id, routes): Observable<any> {
    return this.http.put<any>(environment.ApiUrl + 'api-user/' + id + '/routes', routes, { headers: this.httpHeaders });
  }

  updateApiUserRoutes(id, routes): Observable<any> {
    return this.http.put<any>(environment.ApiUrl + 'api-user-route/' + id + '/edit', routes, { headers: this.httpHeaders });
  }
}
