import { Component, OnInit } from '@angular/core';
import { Editor } from '../Editor';
import { EditorService } from '../editor.service';
import { ActivatedRoute, Router } from '@angular/router';
import { faEdit, faArrowAltCircleLeft, faTimesCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';


@Component({
  selector: 'app-editor-detail',
  templateUrl: './editor-detail.component.html',
  styleUrls: ['./editor-detail.component.css']
})
export class EditorDetailComponent implements OnInit {

  loading: boolean = true;
  error: string = null;
  editor: Editor;

  id;

  notifications : any = [];
  // icons
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;
  faEdit = faEdit;

  constructor(
    private editorService: EditorService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.id = this.activatedRoute.snapshot.params.id;
    this.getEditor();
  }

  getEditor() {
    this.editorService.getEditorById(this.id).subscribe(data => {
      data.details ? this.editor = data.details: this.router.navigate(['/editors']);
      this.editorService.getEditorNotificationsRecipient(this.id).subscribe((data : any) => {
        let notifications : any = [];
        notifications.recipients = [];
        data.details.forEach((result) => {
          notifications.code = result.notification.code;
          let recipient = {'email' : result.email, 'lang' : result.lang.local};
          notifications.recipients.push(recipient);
        })
        this.loading = false;
        this.notifications.push(notifications);
      })
      this.error = null;
    }, error => {
      this.error = error.statusText;
      this.loading = false;
    })
  }

  changeIsActive() {
    this.loading = true;
    this.editorService.putEditor({ ...this.editor, 'isActive': !this.editor.isActive }).subscribe(result => {
      this.editor = result.details;
      this.loading = false;
      this.error = null;
    }, error => { 
      this.loading = false;
      this.error = error.statusText;
    })
  }

}
