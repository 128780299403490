import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DistributionSiteService {

  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  constructor(private http: HttpClient) { }

  getDistributionSites(): Observable<any> {
    return this.http.get(environment.ApiUrl + 'distribution-sites', { headers: this.httpHeaders });
  }

  getDistributionSiteById(id): Observable<any> {
    return this.http.get(environment.ApiUrl + 'distribution-site/' + id, { headers: this.httpHeaders });
  }

  getStatsByDistributionSiteId(id): Observable<any> {
    return this.http.get(environment.ApiUrl + 'distribution-site/' + id + '/api-calls', { headers: this.httpHeaders });
  }

  postDistributionSites(param): Observable<any> {
    return this.http.post(environment.ApiUrl + 'distribution-site', param, { headers: this.httpHeaders });
  }

  putDistributionSites(id, param): Observable<any> {
    return this.http.put(environment.ApiUrl + 'distribution-site/' + id, param, { headers: this.httpHeaders });
  }

  getCountries(): Observable<any> {
    return this.http.get(environment.ApiUrl + 'countries', { headers: this.httpHeaders });
  }

  getDistributors(): Observable<any> {
    return this.http.get(environment.ApiUrl + 'distributors', { headers: this.httpHeaders });
  }

  getPaymentMeans(): Observable<any> {
    return this.http.get(environment.ApiUrl + 'payment-means', { headers: this.httpHeaders });
  }

  getPaymentMeansByDistributionSiteId(id): Observable<any> {
    return this.http.get(environment.ApiUrl + 'payment-means/distribution-site/' + id , { headers: this.httpHeaders });
  }

  getNotifications(): Observable<any> {
    return this.http.get(environment.ApiUrl + 'notifications?for=DistributionSite', { headers: this.httpHeaders });
  }

  getLangs(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'langs', { headers: this.httpHeaders });
  }

  changeStatusDistributionSites(id, param): Observable<any> {
    return this.http.put(environment.ApiUrl + 'distribution-site/change-status/' + id, param, { headers: this.httpHeaders });
  }
}
