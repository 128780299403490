import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faTimes, faCheck, faArrowAltCircleLeft, faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import { DistributorService } from '../distributor.service';
import { EditorService } from 'src/app/editor/editor.service';
import { Distributor } from '../Distributor';
import { Editor } from 'src/app/editor/Editor';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';


@Component({
  selector: 'app-distributor-new-edit',
  templateUrl: './distributor-new-edit.component.html'
})
export class DistributorNewEditComponent implements OnInit {

  error: string = null;
  loading: boolean = false;

  distributor: Distributor = new Distributor();
  editors: Editor[] = [];
  //Id value of the selected editor
  selectedEditor = null;
  id: number = null;

  faTimes = faTimes;
  faCheck = faCheck;
  faArrowAltCircleLeft = faArrowAltCircleLeft;
  faPlusCircle = faPlusCircle;
  faMinusCircle = faMinusCircle;

  form: FormGroup;

  constructor(
    private activeRoute: ActivatedRoute,
    private router: Router,
    private distributorService: DistributorService,
    private editorService: EditorService,
    private formBuilder: FormBuilder
  ) {
    this.form = this.formBuilder.group({
      reference: new FormControl('', [
        Validators.required,
        Validators.maxLength(5),
        Validators.pattern("^[a-zA-Z0-9_-]{1,5}$")
      ]),
      label: new FormControl('', [
        Validators.required,
        Validators.maxLength(255)
      ]),
      isActive: new FormControl(this.distributor.isActive, [
        Validators.required
      ])
    })
  }

  ngOnInit() {
    this.id = this.activeRoute.snapshot.params.id;
    if (this.id) { this.getDistributor(this.id) };
    this.getEditors();
  }

  getDistributor(id) {
    this.setErrorAndLoading(null, true);
    this.distributorService.getDistributorById(id).subscribe(data => {
      //If no data.details (no data found in db) then go to distributors list
      if (data.details) {
        this.distributor = data.details;
        this.form.patchValue({
          reference: data.details.reference,
          label: data.details.name,
          isActive: data.details.isActive
        })
      } else {
        this.router.navigate(['/distributors'])
      }
      this.setErrorAndLoading(null, false);
    }, error => {
      this.setErrorAndLoading(error.statusText, false);
    })
  }

  getEditors() {
    var params = new HttpParams().set('isActive', "true");
    this.editorService.getEditors(params).subscribe(datas => {
      this.editors = datas.details;
    }, error => {
      this.setErrorAndLoading(error.statusText, this.loading);
    })
  }

  setErrorAndLoading(error: string, loading: boolean) {
    this.error = error;
    this.loading = loading;
  }

  addEditor() {
    // Check if editor is already a distribuable editor of distributor
    let isAlreadyInEditors = !this.distributor.editors.filter(editor => this.selectedEditor == editor.id).length;
    if (isAlreadyInEditors) {
      this.distributor.editors = [...this.distributor.editors, ...this.editors.filter(editor => this.selectedEditor == editor.id)];
    }
  }
  removeEditor(id) {
    this.distributor.editors = this.distributor.editors.filter(editor => editor.id != id);
  }

  submitAction() {
    this.form.controls.reference.markAsDirty();
    this.form.controls.label.markAsDirty();
    this.form.controls.isActive.markAsDirty();


    if (this.form.valid) {
      let newDistributor = {
        reference: this.form.value.reference,
        name: this.form.value.label,
        isActive: this.form.value.isActive === 'true' ? true : false,
        id: this.distributor.id,
        editors: this.distributor.editors
      };
      this.setErrorAndLoading(null, true);
      this.id ? this.updateDistributor(newDistributor) : this.createDistributor(newDistributor);
    } else {
      this.setErrorAndLoading('TEMPLATE.FORM.Invalid', this.loading);
    }

  }

  createDistributor(disitributor) {
    if (this.distributor.editors.length == 0) {
      this.setErrorAndLoading("Distributable editor's list should not be null. Please, provide at least one distributable editor.", false);
    } else {
      this.distributorService.postDistributor(disitributor).subscribe(result => {
        this.router.navigate(['/distributor', result.details.id]);
      }, error => {
        this.setErrorAndLoading(error.statusText, false);
      });
    }
  }

  updateDistributor(disitributor) {
    if (this.distributor.editors.length == 0) {
      this.setErrorAndLoading("Distributable editor's list should not be null. Please, provide at least one distributable editor.", false);
    } else {
      this.distributorService.putDistributor(disitributor).subscribe(result => {
        this.router.navigate(['/distributor', result.details.id]);
      }, error => {
        this.setErrorAndLoading(error.statusText, false);
      });
    }
  }

}
