import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Editor } from '../Editor';
import { EditorService } from '../editor.service';

@Component({
  selector: 'app-editor-list',
  templateUrl: './editor-list.component.html',
  styleUrls: ['./editor-list.component.css']
})
export class EditorListComponent implements OnInit {

  @ViewChild('labelFilter', { static: false }) labelFilter: ElementRef;
  @ViewChild('referenceFilter', { static: false }) referenceFilter: ElementRef;
  @ViewChild('isActiveFilter', { static: false }) isActiveFilter: ElementRef;

  editors: Editor[];
  filteredEditors: Editor[];

  loading: Boolean = true;
  error: string = null;

  editorsPerPageOptions: number[] = [5, 10, 20, 50, 100];
  editorsPerPage: number = 5;

  constructor(
    private editorService: EditorService
  ) { }

  ngOnInit() {
    this.getEditors();
  }

  getEditors() {
    this.editorService.getEditors().subscribe(datas => {
      this.editors = datas.details;
      this.filteredEditors = datas.details;
      this.loading = false;
      this.error = null;
    }, error => {
      this.loading = false;
      this.error = error.statusText;
    })
  }
  changeEditorsPerPage(value) {
    this.editorsPerPage = value;
  }

  updateFilter() {
    this.filteredEditors = this.editors
      .filter(editor => this.inputCheck(editor.name, this.labelFilter))
      .filter(editor => this.inputCheck(editor.reference, this.referenceFilter))
      .filter(editor => this.inputCheck(editor.isActive + '', this.isActiveFilter))
  }

  inputCheck(parameter: string = '', criteria: ElementRef) {
    return criteria === undefined ? true : (criteria.nativeElement.value === '' || parameter.match(new RegExp(criteria.nativeElement.value, 'i')));
  }

  clearFilter() {
    this.labelFilter.nativeElement.value = '';
    this.referenceFilter.nativeElement.value = '';
    this.isActiveFilter.nativeElement.value = '';
    this.updateFilter();
  }
}
