import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProductService {


  httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

  TYPE_VOUCHER="VCHR";
  TYPE_ONE_TIME_SUBSCRIPTION="ABO1";
  TYPE_RECURRING_SUBSCRIPTION="ABOR";
  TYPE_RECURRING_PAYMENT="ABORP";

  constructor(private http: HttpClient, private _router: Router) { }

  ngOnInit() { }

  getKartiProduct(kartiBrandId: number, kartiProductId: number): Observable<any> {
    return this.http.get<any>(`${environment.ApiUrl}product/karti/${kartiBrandId}/${kartiProductId}`, { headers: this.httpHeaders });
  }

  getProducts(params = new HttpParams()): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'products?' + params.toString(), { headers: this.httpHeaders });
  }

  getProduct(productId): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'product/' + productId, { headers: this.httpHeaders });
  }

  getProductWithDistributableProduct(id): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'product/' + id + '/distributable-products', { headers: this.httpHeaders });
  }

  getProductsTypes(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'products/types', { headers: this.httpHeaders });
  }

  getProductsSubscriptionRecurrences(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'products/subscription-recurrences', { headers: this.httpHeaders });
  }

  getProductAvailableDistributors(productId): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'product/' + productId + '/distributors', { headers: this.httpHeaders });
  }

  getProductVouchersKpi(): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'products/VCHR/kpi', { headers: this.httpHeaders });
  }

  postNewProduct(params): Observable<any> {
    return this.http.post<any>(environment.ApiUrl + 'product', params, { headers: this.httpHeaders });
  }

  postNewDistributableProduct(params): Observable<any> {
    return this.http.post<any>(environment.ApiUrl + 'distributable-product', params, { headers: this.httpHeaders });
  }

  editDistributableProduct = (distributableProductId,  params): Observable<any> => {
    return this.http.put(`${environment.ApiUrl}distributable-product/${distributableProductId}`,params, { headers: this.httpHeaders })
  }

  getDistributableProduct(distributableId): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'distributable-product/' + distributableId, { headers: this.httpHeaders });
  }

  editDistributableProductActivation(distributableProductId): Observable<any> {
    return this.http.post<any>(environment.ApiUrl + 'distributable-product-activation/' + distributableProductId, { headers: this.httpHeaders });
  }

  putEditProductActivation(id, params): Observable<any> {
    return this.http.put<any>(environment.ApiUrl + 'product-activation/' + id, params, { headers: this.httpHeaders });
  }

  putEditProduct(id, params): Observable<any> {
    return this.http.put<any>(environment.ApiUrl + 'product/' + id, params, { headers: this.httpHeaders });
  }

  getActiveDistributableProductPriceByDistributableProduct(distributableProductId): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'distributable-product-price/distributable-product/' + distributableProductId + '/active', { headers: this.httpHeaders });
  }

  getDistributableProductPriceHistoryByDistributableProduct(distributableProductId): Observable<any> {
    return this.http.get<any>(environment.ApiUrl + 'distributable-product-price/distributable-product/' + distributableProductId + '/history', { headers: this.httpHeaders });
  }
}
