import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class ParameterService {

    httpHeaders = new HttpHeaders().set('Content-Type', 'application/json');

    constructor(private http: HttpClient, private _router: Router) { }

    ngOnInit() { }

    getVoucherThresholdsParameters(): Observable<any> {
        return this.http.get<any>(environment.ApiUrl + 'parameter/voucher-thresholds', { headers: this.httpHeaders });
    }

}
