import { Component, OnInit, ViewChild, ElementRef, asNativeElements } from '@angular/core';
import { DistributionSiteService } from '../distribution-site.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { DistributorService } from 'src/app/distributor/distributor.service';
import { CountryService } from 'src/app/country/country.service';
import { DistributionSite } from '../DistributionSite';
import { Distributor } from 'src/app/distributor/Distributor';
import { Country } from 'src/app/country/Country';

@Component({
  selector: 'app-distribution-site-list',
  templateUrl: './distribution-site-list.component.html',
  styleUrls: ['./distribution-site-list.component.css']
})
export class DistributionSiteListComponent implements OnInit {

  @ViewChild('labelFilter', { static: false }) labelFilter: ElementRef;
  @ViewChild('referenceFilter', { static: false }) referenceFilter: ElementRef;
  @ViewChild('distributorFilter', { static: false }) distributorFilter: ElementRef;
  @ViewChild('countryFilter', { static: false }) countryFilter: ElementRef;
  @ViewChild('isActiveFilter', { static: false }) isActiveFilter: ElementRef;
  @ViewChild('urlAutoReturnBasicAuthFilter', { static: false }) urlAutoReturnBasicAuthFilter: ElementRef;

  sites: DistributionSite[] = [];
  filteredSites: DistributionSite[] = [];

  // Filtering options
  distributors: Distributor[] = [];
  countries: Country[] = [];

  // Item number per page
  distributionSitesPerPageOptions: number[] = [5, 10, 20, 50, 100];
  distributionSitesPerPage: number = 5;

  loading: boolean = true;
  error: string = null;

  constructor(
    private distributionSiteService: DistributionSiteService,
    private distributorService: DistributorService,
    private countryService: CountryService
  ) { }

  ngOnInit() {
    this.getDistributionSites();

  }

  getDistributionSites() {
    this.distributionSiteService.getDistributionSites().subscribe(data => {
      this.loading = false;
      this.error = null;
      this.filteredSites = data.details;
      this.sites = data.details;
      this.getCountries();
      this.getDistributors();
    },
      error => {
        this.error = error.statusText;
        this.loading = false;

      });
  }

  // Get filtering options
  getCountries() {
    this.countryService.getCountries().subscribe(datas => {
      this.countries = datas.details;
    })
  }
  getDistributors() {
    this.distributorService.getDistributors().subscribe(datas => {
      this.distributors = datas.details;
    })
  }

  clearFilters() {
    this.labelFilter.nativeElement.value = '';
    this.referenceFilter.nativeElement.value = '';
    this.distributorFilter.nativeElement.value = '';
    this.countryFilter.nativeElement.value = '';
    this.isActiveFilter.nativeElement.value = '';
    this.urlAutoReturnBasicAuthFilter.nativeElement.value = '';
    this.updateFilter();
  }
  updateFilter() {
    //Field are filtered IF input is written or if it match with the input
    this.filteredSites = this.sites
      // Label filter
      .filter(site => this.filterValue(site.name, this.labelFilter))
      // Reference filter
      .filter(site => this.filterValue(site.reference, this.referenceFilter))
      // Distributor filter
      .filter(site => this.filterValue(site.distributor.name, this.distributorFilter))
      // Country filter
      .filter(site => this.filterValue(site.country.name, this.countryFilter))
      // IsActive filter
      .filter(site => this.filterValue(site.isActive + '', this.isActiveFilter))
      // urlAutoReturnBasicAuth filter
      .filter(site => this.filterValue(site.urlAutoReturnBasicAuth + '', this.urlAutoReturnBasicAuthFilter))

  }
  filterValue(parameter = '', criteria: ElementRef) {
    return (
      criteria === undefined
        || criteria.nativeElement.value === ''
        || parameter === null ?
        true : parameter.match(new RegExp(criteria.nativeElement.value, 'i')))
  }

  changeDistributionSitesPerPage(value) {
    this.distributionSitesPerPage = value;
  }
}
